<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Events" />
        <EventsContent />
        <Footer /> 
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import EventsContent from '../Events/EventsContent'
import Footer from '../Layout/Footer'

export default {
    name: 'EventsPage',
    components: {
        Navbar,
        PageTitle,
        EventsContent,
        Footer,
    }
}
</script>
