<template>
    <div class="membership-levels-area pt-100 pb-70">
        <div class="container">
            <div class="membership-levels-table table-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th></th>
                            <th>
                                <h2>$500</h2>
                                <h3>Silver Membership</h3>
                                <span class="desc">You can view few features</span>
                            </th>
                            <th>
                                <h2>$1,000</h2>
                                <h3>Gold Membership</h3>
                                <span class="desc">You can view most features</span>
                            </th>
                            <th>
                                <h2>$1,500</h2>
                                <h3>Diamond Membership</h3>
                                <span class="desc">You can view all features</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Number of features</td>
                            <td>4</td>
                            <td>8</td>
                            <td>15</td>
                        </tr>
                        <tr>
                            <td><a href="#">Investment Fundamentals & Data Analytics</a></td>
                            <td class="item-check"><i class='bx bx-check'></i></td>
                            <td class="item-check"><i class='bx bx-check'></i></td>
                            <td class="item-check"><i class='bx bx-check'></i></td>
                        </tr>
                        <tr>
                            <td><a href="#">Hands-On Python & R In Data Science</a></td>
                            <td class="item-check"><i class='bx bx-check'></i></td>
                            <td class="item-check"><i class='bx bx-check'></i></td>
                            <td class="item-check"><i class='bx bx-check'></i></td>
                        </tr>
                        <tr>
                            <td><a href="#">Complete Data Science Bootcamp</a></td>
                            <td class="item-check"><i class='bx bx-check'></i></td>
                            <td class="item-check"><i class='bx bx-check'></i></td>
                            <td class="item-check"><i class='bx bx-check'></i></td>
                        </tr>
                        <tr>
                            <td><a href="#">Programming Masterclass for Developers</a></td>
                            <td class="item-none"><i class='bx bx-x'></i></td>
                            <td class="item-check"><i class='bx bx-check'></i></td>
                            <td class="item-check"><i class='bx bx-check'></i></td>
                        </tr>
                        <tr>
                            <td><a href="#">Hands-On Artificial Neural Networks</a></td>
                            <td class="item-none"><i class='bx bx-x'></i></td>
                            <td class="item-check"><i class='bx bx-check'></i></td>
                            <td class="item-check"><i class='bx bx-check'></i></td>
                        </tr>
                        <tr>
                            <td><a href="#">PRINCE2® Practitioner Certification Training</a></td>
                            <td class="item-none"><i class='bx bx-x'></i></td>
                            <td class="item-check"><i class='bx bx-check'></i></td>
                            <td class="item-check"><i class='bx bx-check'></i></td>
                        </tr>
                        <tr>
                            <td><a href="#">PMI-RMP® Certification Training</a></td>
                            <td class="item-none"><i class='bx bx-x'></i></td>
                            <td class="item-none"><i class='bx bx-x'></i></td>
                            <td class="item-check"><i class='bx bx-check'></i></td>
                        </tr>
                        <tr>
                            <td><a href="#">PRINCE2® Foundation Certification Training</a></td>
                            <td class="item-none"><i class='bx bx-x'></i></td>
                            <td class="item-none"><i class='bx bx-x'></i></td>
                            <td class="item-check"><i class='bx bx-check'></i></td>
                        </tr>
                        <tr>
                            <td><a href="#">PMP® Plus</a></td>
                            <td class="item-none"><i class='bx bx-x'></i></td>
                            <td class="item-none"><i class='bx bx-x'></i></td>
                            <td class="item-check"><i class='bx bx-check'></i></td>
                        </tr>
                        <tr>
                            <td><a href="#">PMP® Renewal Pack</a></td>
                            <td class="item-none"><i class='bx bx-x'></i></td>
                            <td class="item-check"><i class='bx bx-check'></i></td>
                            <td class="item-check"><i class='bx bx-check'></i></td>
                        </tr>
                        <tr>
                            <td><a href="#">Digital Project Manager</a></td>
                            <td class="item-none"><i class='bx bx-x'></i></td>
                            <td class="item-none"><i class='bx bx-x'></i></td>
                            <td class="item-check"><i class='bx bx-check'></i></td>
                        </tr>
                        <tr>
                            <td><a href="#">Agile Project Management; Agile Delivery</a></td>
                            <td class="item-none"><i class='bx bx-x'></i></td>
                            <td class="item-none"><i class='bx bx-x'></i></td>
                            <td class="item-check"><i class='bx bx-check'></i></td>
                        </tr>
                        <tr>
                            <td><a href="#">Vue JS 2 - The Complete Guide</a></td>
                            <td class="item-none"><i class='bx bx-x'></i></td>
                            <td class="item-none"><i class='bx bx-x'></i></td>
                            <td class="item-check"><i class='bx bx-check'></i></td>
                        </tr>
                        <tr>
                            <td><a href="#">Everything You Need to Program in Python</a></td>
                            <td class="item-none"><i class='bx bx-x'></i></td>
                            <td class="item-none"><i class='bx bx-x'></i></td>
                            <td class="item-check"><i class='bx bx-check'></i></td>
                        </tr>
                        <tr>
                            <td><a href="#">Mathematical Foundation For Machine Learning</a></td>
                            <td class="item-none"><i class='bx bx-x'></i></td>
                            <td class="item-none"><i class='bx bx-x'></i></td>
                            <td class="item-check"><i class='bx bx-check'></i></td>
                        </tr>
                        <tr>
                            <td><a href="#">Drawing Course - Beginner to Advanced</a></td>
                            <td class="item-none"><i class='bx bx-x'></i></td>
                            <td class="item-none"><i class='bx bx-x'></i></td>
                            <td class="item-check"><i class='bx bx-check'></i></td>
                        </tr>
                        <tr>
                            <td><a href="#">Deep Learning and Artificial Intelligence</a></td>
                            <td class="item-none"><i class='bx bx-x'></i></td>
                            <td class="item-none"><i class='bx bx-x'></i></td>
                            <td class="item-check"><i class='bx bx-check'></i></td>
                        </tr>
                        <tr>
                            <td><a href="#">Information About UI/UX Design Degree</a></td>
                            <td class="item-check"><i class='bx bx-check'></i></td>
                            <td class="item-check"><i class='bx bx-check'></i></td>
                            <td class="item-check"><i class='bx bx-check'></i></td>
                        </tr>
                        <tr>
                            <td><a href="#">Photography Photo modify and Beautiful</a></td>
                            <td class="item-none"><i class='bx bx-x'></i></td>
                            <td class="item-none"><i class='bx bx-x'></i></td>
                            <td class="item-check"><i class='bx bx-check'></i></td>
                        </tr>
                        <tr>
                            <td><a href="#">Professional IT Expert Certificate Course</a></td>
                            <td class="item-none"><i class='bx bx-x'></i></td>
                            <td class="item-none"><i class='bx bx-x'></i></td>
                            <td class="item-check"><i class='bx bx-check'></i></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>
                                <a href="#" class="select-btn">Get it now</a>
                            </td>
                            <td>
                                <a href="#" class="select-btn">Get it now</a>
                            </td>
                            <td>
                                <a href="#" class="select-btn">Get it now</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>   

<script>

export default {
    name: 'MembershipLevelsContent'
}
</script>