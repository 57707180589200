<template>
    <div class="upcoming-bootcamps-area ptb-100 bg-fafafb">
        <div class="container">
            <div class="upcoming-bootcamps-content">
                <h2>Check Full Schedule for In-person Data Science Bootcamps</h2>
                <router-link to="/events" class="default-btn"><i class="flaticon-right"></i>Check Out All Bootcamps<span></span></router-link>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'CheckFullSchedule'
}
</script>