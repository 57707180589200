<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Services" />
        <ServicesContent />
        <WeLikeToStartThree />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import ServicesContent from '../ServicesOne/ServicesContent'
import WeLikeToStartThree from '../Common/WeLikeToStartThree'
import Footer from '../Layout/Footer'

export default {
    name: 'ServicesPageOne',
    components: {
        Navbar,
        PageTitle,
        ServicesContent,
        WeLikeToStartThree,
        Footer,
    }
}
</script>
