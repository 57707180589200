<template>
    <div class="faq-area ptb-100">
        <div class="container">
            <div class="faq-accordion-tab">
                <div class="tabs">
                    <ul class="tabs__header">
                        <li 
                            class="tabs__header-item" 
                            v-for="tab in tabs" 
                            v-on:click="selectTab (tab.id)" 
                            v-bind:class="{ 'active': activeTab == tab.id }"
                            :key="tab.id"
                        >
                            <i :class="tab.icon"></i>
                            {{tab.title}}
                        </li>
                    </ul>
                </div>

                <div class="tabs__container">
                    <div 
                        class="tabs__list"
                        ref='tabsList'
                    >
                        <div 
                            class="tabs__list-tab"
                            v-for="tab in tabs"
                            v-bind:class="{ 'active': activeTab == tab.id }"
                            :key="tab.id"
                        >
                            <div class="faq-accordion">
                                <accordion>
                                    <accordion-item
                                        v-for="accordion in tab.faqAccordion"
                                        :key="accordion.id"
                                    >
                                        <template v-slot:accordion-trigger>
                                            <button class="accordion-button">
                                                <i :class='accordion.icon'></i>
                                                {{accordion.title}}
                                            </button>
                                        </template>
                                        <template v-slot:accordion-content>
                                            <p>{{accordion.desc}}</p>
                                        </template>
                                    </accordion-item>
                                </accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Accordion from "../Common/Accordion";
import AccordionItem from "../Common/AccordionItem";

export default {
    name: 'FaqContent',
    components: {
        Accordion,
        AccordionItem
    },
    data () {
        return {
            activeTab: 1,
            offsetRight: 0,
            tabs: [
                {
                    id: 1,
                    icon: 'bx bx-flag',
                    title: 'Getting Started',
                    faqAccordion: [
                        {
                            id: 1,
                            icon: 'bx bx-chevron-down',
                            title: 'Q1. What is Data Science? List the differences between supervised and unsupervised learning.',
                            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                        },
                        {
                            id: 2,
                            icon: 'bx bx-chevron-down',
                            title: 'Q2. What is Selection Parix.com?',
                            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                        },
                        {
                            id: 3,
                            icon: 'bx bx-chevron-down',
                            title: 'Q3. What is Parix-variance trade-off?',
                            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                        },
                        {
                            id: 4,
                            icon: 'bx bx-chevron-down',
                            title: 'Q4. What is a confusion matrix?',
                            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                        },
                        {
                            id: 5,
                            icon: 'bx bx-chevron-down',
                            title: 'Q5. What is the difference between “long” and “wide” format data?',
                            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                        },
                    ]
                },
                {
                    id: 2,
                    icon: 'bx bxs-badge-dollar',
                    title: 'Pricing & Planes',
                    faqAccordion: [
                        {
                            id: 1,
                            icon: 'bx bx-chevron-down',
                            title: 'Q1. What is Data Science? List the differences between supervised and unsupervised learning.',
                            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                        },
                        {
                            id: 2,
                            icon: 'bx bx-chevron-down',
                            title: 'Q2. What is Selection Parix.com?',
                            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                        },
                        {
                            id: 3,
                            icon: 'bx bx-chevron-down',
                            title: 'Q3. What is Parix-variance trade-off?',
                            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                        },
                        {
                            id: 4,
                            icon: 'bx bx-chevron-down',
                            title: 'Q4. What is a confusion matrix?',
                            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                        },
                        {
                            id: 5,
                            icon: 'bx bx-chevron-down',
                            title: 'Q5. What is the difference between “long” and “wide” format data?',
                            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                        },
                    ]
                },
                {
                    id: 3,
                    icon: 'bx bx-shopping-bag',
                    title: 'Sales Question',
                    faqAccordion: [
                        {
                            id: 1,
                            icon: 'bx bx-chevron-down',
                            title: 'Q1. What is Data Science? List the differences between supervised and unsupervised learning.',
                            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                        },
                        {
                            id: 2,
                            icon: 'bx bx-chevron-down',
                            title: 'Q2. What is Selection Parix.com?',
                            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                        },
                        {
                            id: 3,
                            icon: 'bx bx-chevron-down',
                            title: 'Q3. What is Parix-variance trade-off?',
                            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                        },
                        {
                            id: 4,
                            icon: 'bx bx-chevron-down',
                            title: 'Q4. What is a confusion matrix?',
                            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                        },
                        {
                            id: 5,
                            icon: 'bx bx-chevron-down',
                            title: 'Q5. What is the difference between “long” and “wide” format data?',
                            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                        },
                    ]
                },
                {
                    id: 4,
                    icon: 'bx bx-book-open',
                    title: 'Usage Guides',
                    faqAccordion: [
                        {
                            id: 1,
                            icon: 'bx bx-chevron-down',
                            title: 'Q1. What is Data Science? List the differences between supervised and unsupervised learning.',
                            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                        },
                        {
                            id: 2,
                            icon: 'bx bx-chevron-down',
                            title: 'Q2. What is Selection Parix.com?',
                            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                        },
                        {
                            id: 3,
                            icon: 'bx bx-chevron-down',
                            title: 'Q3. What is Parix-variance trade-off?',
                            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                        },
                        {
                            id: 4,
                            icon: 'bx bx-chevron-down',
                            title: 'Q4. What is a confusion matrix?',
                            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                        },
                        {
                            id: 5,
                            icon: 'bx bx-chevron-down',
                            title: 'Q5. What is the difference between “long” and “wide” format data?',
                            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                        },
                    ]
                },
                {
                    id: 5,
                    icon: 'bx bx-info-circle',
                    title: 'General Guide',
                    faqAccordion: [
                        {
                            id: 1,
                            icon: 'bx bx-chevron-down',
                            title: 'Q1. What is Data Science? List the differences between supervised and unsupervised learning.',
                            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                        },
                        {
                            id: 2,
                            icon: 'bx bx-chevron-down',
                            title: 'Q2. What is Selection Parix.com?',
                            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                        },
                        {
                            id: 3,
                            icon: 'bx bx-chevron-down',
                            title: 'Q3. What is Parix-variance trade-off?',
                            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                        },
                        {
                            id: 4,
                            icon: 'bx bx-chevron-down',
                            title: 'Q4. What is a confusion matrix?',
                            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                        },
                        {
                            id: 5,
                            icon: 'bx bx-chevron-down',
                            title: 'Q5. What is the difference between “long” and “wide” format data?',
                            desc: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
                        },
                    ]
                },
            ]
        }
    },
    methods: {
        selectTab (id) {
            let tabsList = this.$refs.tabsList
            this.activeTab = id
            this.offsetRight = tabsList.clientWidth * (id - 1)
            tabsList.style.right = this.offsetRight + 'px'
        }
    },
    mounted () {
        let tabsList = this.$refs.tabsList
        tabsList.style.right = this.offsetRight + 'px'
    }
}
</script>