<template>
  <div class="funfacts-area pb-70">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-sm-3 col-6 col-md-3">
          <div class="single-funfacts-item">
            <div class="icon">
              <img
                src="../../assets/images/funfacts/funfacts1.png"
                alt="image"
              />
            </div>
            <h3>10 Years</h3>
            <p>On the market</p>
          </div>
        </div>

        <div class="col-lg-3 col-sm-3 col-6 col-md-3">
          <div class="single-funfacts-item">
            <div class="icon">
              <img
                src="../../assets/images/funfacts/funfacts2.png"
                alt="image"
              />
            </div>
            <h3>10,000+</h3>
            <p>Learners</p>
          </div>
        </div>

        <div class="col-lg-3 col-sm-3 col-6 col-md-3">
          <div class="single-funfacts-item">
            <div class="icon">
              <img
                src="../../assets/images/funfacts/funfacts3.png"
                alt="image"
              />
            </div>
            <h3>125+</h3>
            <p>Course</p>
          </div>
        </div>

        <div class="col-lg-3 col-sm-3 col-6 col-md-3">
          <div class="single-funfacts-item">
            <div class="icon">
              <img
                src="../../assets/images/funfacts/funfacts5.png"
                alt="image"
              />
            </div>
            <h3>196+</h3>
            <p>Countries</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Funfacts",
};
</script>