<template>
    <div class="banner-wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-5 col-md-12">
                    <div class="banner-wrapper-content">
                        <h1>Managed Analytics and Data Analysis Platform</h1>
                        <p>We have created a cloud based tool to do your all boring tasks related to data analysis and decesion making based on the nature of data!</p>
                        
                        <div class="btn-box">
                            <router-link to="/contact" class="default-btn"><i class="flaticon-right"></i>About Us<span></span></router-link>
                            <router-link to="/profile-authentication" class="default-btn"><i class="flaticon-tick"></i>Get Started<span></span></router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="banner-wrapper-animation-image">
                        <img src="../../assets/images/main-banner/banner-four/banner-four1.png" alt="image">
                        <img src="../../assets/images/main-banner/banner-four/banner-four2.png" alt="image">
                        <img src="../../assets/images/main-banner/banner-four/banner-four3.png" alt="image">
                        <img src="../../assets/images/main-banner/banner-four/banner-four4.png" alt="image">
                        <img src="../../assets/images/main-banner/banner-four/banner-four5.png" alt="image">
                        <img src="../../assets/images/main-banner/banner-four/banner-four6.png" alt="image">
                        <img src="../../assets/images/main-banner/banner-four/banner-four7.png" alt="image">
                        <img src="../../assets/images/main-banner/banner-four/banner-four8.png" alt="image">
                        <img src="../../assets/images/main-banner/banner-four/banner-four9.png" alt="image">
                        <img src="../../assets/images/main-banner/banner-four/banner-four10.png"  alt="image">
                        <img src="../../assets/images/main-banner/banner-four/banner-four-main-pic.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'MainBanner'
}
</script>