<template>
  <div class="projects-area pt-100 pb-70">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="single-projects-box">
            <div class="image">
              <img
                src="../../assets/images/projects/projects1.jpg"
                alt="image"
              />

              <router-link to="/case-studies-details-two" class="link-btn"
                ><i class="bx bx-plus"></i
              ></router-link>
            </div>

            <div class="content">
              <h3>
                <router-link to="/case-studies-details-two"
                  >Movie Recommendation</router-link
                >
              </h3>
              <span>System Project</span>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6">
          <div class="single-projects-box">
            <div class="image">
              <img
                src="../../assets/images/projects/projects2.jpg"
                alt="image"
              />

              <router-link to="/case-studies-details-two" class="link-btn"
                ><i class="bx bx-plus"></i
              ></router-link>
            </div>

            <div class="content">
              <h3>
                <router-link to="/case-studies-details-two"
                  >Customer Segmentation</router-link
                >
              </h3>
              <span>Machine Learning</span>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6">
          <div class="single-projects-box">
            <div class="image">
              <img
                src="../../assets/images/projects/projects3.jpg"
                alt="image"
              />

              <router-link to="/case-studies-details-two" class="link-btn"
                ><i class="bx bx-plus"></i
              ></router-link>
            </div>

            <div class="content">
              <h3>
                <router-link to="/case-studies-details-two"
                  >Data Analysis</router-link
                >
              </h3>
              <span>Web Project</span>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6">
          <div class="single-projects-box">
            <div class="image">
              <img
                src="../../assets/images/projects/projects4.jpg"
                alt="image"
              />

              <router-link to="/case-studies-details-two" class="link-btn"
                ><i class="bx bx-plus"></i
              ></router-link>
            </div>

            <div class="content">
              <h3>
                <router-link to="/case-studies-details-two"
                  >Detection Project</router-link
                >
              </h3>
              <span>Programming</span>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6">
          <div class="single-projects-box">
            <div class="image">
              <img
                src="../../assets/images/projects/projects5.jpg"
                alt="image"
              />

              <router-link to="/case-studies-details-two" class="link-btn"
                ><i class="bx bx-plus"></i
              ></router-link>
            </div>

            <div class="content">
              <h3>
                <router-link to="/case-studies-details-two"
                  >Data Scientist</router-link
                >
              </h3>
              <span>Data Science</span>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6">
          <div class="single-projects-box">
            <div class="image">
              <img
                src="../../assets/images/projects/projects6.jpg"
                alt="image"
              />

              <router-link to="/case-studies-details-two" class="link-btn"
                ><i class="bx bx-plus"></i
              ></router-link>
            </div>

            <div class="content">
              <h3>
                <router-link to="/case-studies-details-two"
                  >Benefits Research</router-link
                >
              </h3>
              <span>Science Projects</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CaseStudiesContent",
};
</script>