<template>
  <div class="how-its-work-area pt-70 pb-100">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="how-its-work-content">
            <span v-if="data.subtitle" class="sub-title"
              ><img src="../../assets/images/star-icon.png" alt="image" />{{
                data.subtitle
              }}</span
            >
            <h2 v-if="data.title">
              {{ data.title }}<span class="overlay"></span>
            </h2>
            <p v-if="data.description">
              {{ data.description }}
            </p>
            <div
              v-if="
                data.block1_title ||
                data.block1_text ||
                data.block2_title ||
                data.block2_text ||
                data.block3_title ||
                data.block3_text
              "
              class="inner-box"
            >
              <div
                class="single-item"
                v-if="data.block1_title || data.block1_text"
              >
                <div class="count-box">1</div>
                <h3>{{ data.block1_title }}</h3>
                <p>
                  {{ data.block1_text }}
                </p>
              </div>
              <div
                class="single-item"
                v-if="data.block2_title || data.block2_text"
              >
                <div class="count-box">2</div>
                <h3>{{ data.block2_title }}</h3>
                <p>
                  {{ data.block2_text }}
                </p>
              </div>
              <div
                class="single-item"
                v-if="data.block3_title || data.block3_text"
              >
                <div class="count-box">3</div>
                <h3>{{ data.block3_title }}</h3>
                <p>
                  {{ data.block3_text }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div v-if="data.background.data" class="col-lg-6 col-md-12">
          <div class="how-its-work-image" data-tilt>
            <img
              :src="`${process.VUE_APP_STRAPI_URL}${data.background.data.attributes.url}`"
              alt="image"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheDataScienceProcess",

  props: {
    data: Object,
  },

  data() {
    return {
      process: process.env,
    };
  },
};
</script>