<template>
  <div class="testimonials-area bg-f1f8fb" style="padding-bottom: 10px">
    <div class="container">
      <div class="section-title">
        <span v-if="data.subtitle" class="sub-title"
          ><img src="../../assets/images/star-icon.png" alt="image" />
          {{ data.subtitle }}</span
        >
        <h2 v-if="data.title">{{ data.title }}</h2>
        <p v-if="data.description">
          {{ data.description }}
        </p>
      </div>

      <div class="testimonials-slides">
        <carousel
          :autoplay="5000"
          :settings="settings"
          :wrapAround="true"
          :breakpoints="breakpoints"
        >
          <slide v-for="slide in data.ReviewItem" :key="slide.id">
            <div class="single-testimonials-item">
              <p>{{ slide.text }}</p>
              <div class="client-info">
                <div class="d-flex justify-content-center align-items-center">
                  <img
                    :src="`${process.VUE_APP_STRAPI_URL}${slide.image.data.attributes.url}`"
                    class="shadow-sm"
                    alt="image"
                  />
                  <div class="title">
                    <h3>{{ slide.name }}</h3>
                    <span>{{ slide.position }}</span>
                  </div>
                </div>
              </div>
            </div>
          </slide>

          <template #addons>
            <navigation>
              <template #next>
                <i class="flaticon-right-1"></i>
              </template>
              <template #prev>
                <i class="flaticon-left-1"></i>
              </template>
            </navigation>
          </template>
        </carousel>
      </div>

      <div class="testimonials-view-btn text-center">
        <AppLink :link="data.ButtonLink" class="default-btn">
          <i class="flaticon-view"></i>
          {{ data.ButtonText }}<span></span>
        </AppLink>
      </div>
    </div>

    <div class="shape-img1">
      <img src="../../assets/images/shape/shape1.svg" alt="image" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import AppLink from "@/components/Link.vue";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "WhatOurClientsSaying",
  components: {
    Carousel,
    Slide,
    Navigation,
    AppLink,
  },
  props: {
    data: Object,
  },
  data: () => ({
    settings: {
      itemsToShow: 1,
      snapAlign: "center",
    },
    carouselItems: [
      {
        id: 1,
        image: require("../../assets/images/testimonials/testimonials1.jpg"),
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
        name: "Alex Maxwell",
        position: "CEO at EnvyTheme",
      },
      {
        id: 2,
        image: require("../../assets/images/testimonials/testimonials2.jpg"),
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
        name: "David Warner",
        position: "CEO at Envato",
      },
      {
        id: 3,
        image: require("../../assets/images/testimonials/testimonials3.jpg"),
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
        name: "Sarah Taylor",
        position: "CEO at ThemeForest",
      },
      {
        id: 4,
        image: require("../../assets/images/testimonials/testimonials1.jpg"),
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
        name: "Alex Maxwell",
        position: "CEO at EnvyTheme",
      },
      {
        id: 5,
        image: require("../../assets/images/testimonials/testimonials2.jpg"),
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
        name: "David Warner",
        position: "CEO at Envato",
      },
      {
        id: 6,
        image: require("../../assets/images/testimonials/testimonials3.jpg"),
        desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
        name: "Sarah Taylor",
        position: "CEO at ThemeForest",
      },
    ],
    breakpoints: {
      0: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      768: {
        itemsToShow: 2,
        snapAlign: "left",
      },
      1024: {
        itemsToShow: 2,
        snapAlign: "left",
      },
    },
    process: process.env,
  }),
});
</script>

<style lang="scss" scoped>
.single-testimonials-item {
  width: 100%;
}
</style>