<template>
    <div>
        <NavbarStyleThree />
        <PageTitle pageTitle="Products List" />
        <ProductsContent />
        <Footer /> 
    </div>
</template>

<script>
import NavbarStyleThree from '../Layout/NavbarStyleThree'
import PageTitle from '../Common/PageTitle'
import ProductsContent from '../ProductsTwo/ProductsContent'
import Footer from '../Layout/Footer'

export default {
    name: 'ProductsPageTwo',
    components: {
        NavbarStyleThree,
        PageTitle,
        ProductsContent,
        Footer,
    }
}
</script>
