<template>
    <div>
        <Navbar />
        <MainBanner />
        <WeDifferentFrom />
        <DriveDigitalRevolution />
        <WeOfferProfessional />
        <TheDataScience />
        <DataSciencePharmaceutical />
        <OurDataScientist />
        <WhatOurClientsSaying />
        <Partner />
        <LatestValuableInsights />
        <WeLikeToStart />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import MainBanner from '../StaticBannerImageOne/MainBanner'
import WeDifferentFrom from '../StaticBannerImageOne/WeDifferentFrom'
import DriveDigitalRevolution from '../StaticBannerImageOne/DriveDigitalRevolution'
import WeOfferProfessional from '../StaticBannerImageOne/WeOfferProfessional'
import TheDataScience from '../StaticBannerImageOne/TheDataScience'
import DataSciencePharmaceutical from '../Common/DataSciencePharmaceutical'
import OurDataScientist from '../Common/OurDataScientist'
import WhatOurClientsSaying from '../Common/WhatOurClientsSaying'
import Partner from '../Common/Partner'
import LatestValuableInsights from '../Common/LatestValuableInsights'
import WeLikeToStart from '../Common/WeLikeToStart'
import Footer from '../Layout/Footer'

export default {
    name: 'StaticBannerImagePageOne',
    components: {
        Navbar,
        MainBanner,
        WeDifferentFrom,
        DriveDigitalRevolution,
        WeOfferProfessional,
        TheDataScience,
        DataSciencePharmaceutical,
        OurDataScientist,
        WhatOurClientsSaying,
        Partner,
        LatestValuableInsights,
        WeLikeToStart,
        Footer,
    }
}
</script>
