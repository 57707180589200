<template>
    <div class="testimonials-area ptb-100">
        <div class="container">
            <div class="section-title">
                <span class="sub-title"><img src="../../assets/images/star-icon.png" alt="image"> Testimonials</span>
                <h2>What Our Clients are Saying?</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
            </div>

            <div class="testimonials-slides">
                <carousel
                    :autoplay="5000"
                    :settings='settings'
                    :wrapAround="true"
                    :breakpoints='breakpoints'
                >
                    <slide 
                        v-for="slide in carouselItems" 
                        :key="slide.id"
                    >
                        <div class="single-testimonials-box bg-fafafb">
                            <img :src="slide.image" class="shadow-sm" alt="image">
                            <p>{{slide.desc}}</p>
                            <div class="client-info">
                                <h3>{{slide.name}}</h3>
                                <span>{{slide.position}}</span>
                            </div>
                        </div>
                    </slide>

                    <template #addons>
                        <navigation>
                            <template #next>
                                <i class='flaticon-right-1'></i>
                            </template>
                            <template #prev>
                                <i class='flaticon-left-1'></i>
                            </template>
                        </navigation>
                    </template>
                </carousel>
            </div>

            <div class="testimonials-view-btn text-center">
                <router-link to="/testimonials" class="default-btn">
                    <i class="flaticon-view"></i>
                    Check Out All Reviews<span></span>
                </router-link>
            </div>
        </div>

        <div class="shape-img1"><img src="../../assets/images/shape/shape1.svg" alt="image"></div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'WhatOurClientsSaying',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                image: require('../../assets/images/testimonials/testimonials1.jpg'),
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
                name: 'Alex Maxwell',
                position: 'CEO at EnvyTheme',
            },
            {
                id: 2,
                image: require('../../assets/images/testimonials/testimonials2.jpg'),
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
                name: 'David Warner',
                position: 'CEO at Envato',
            },
            {
                id: 3,
                image: require('../../assets/images/testimonials/testimonials3.jpg'),
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
                name: 'Sarah Taylor',
                position: 'CEO at ThemeForest',
            },
            {
                id: 4,
                image: require('../../assets/images/testimonials/testimonials1.jpg'),
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
                name: 'Alex Maxwell',
                position: 'CEO at EnvyTheme',
            },
            {
                id: 5,
                image: require('../../assets/images/testimonials/testimonials2.jpg'),
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
                name: 'David Warner',
                position: 'CEO at Envato',
            },
            {
                id: 6,
                image: require('../../assets/images/testimonials/testimonials3.jpg'),
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
                name: 'Sarah Taylor',
                position: 'CEO at ThemeForest',
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            768: {
                itemsToShow: 2,
                snapAlign: 'left',
            },
            1024: {
                itemsToShow: 2,
                snapAlign: 'left',
            },
        },
    }),
})
</script>