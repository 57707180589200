<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Courses Grid" />
        <CoursesContent />
        <Footer /> 
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import CoursesContent from '../CoursesGrid/CoursesContent'
import Footer from '../Layout/Footer'

export default {
    name: 'CoursesGridPage',
    components: {
        Navbar,
        PageTitle,
        CoursesContent,
        Footer,
    }
}
</script>
