<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Case Studies Details" />
        <CaseStudiesDetailsContent />
        <MoreProjects />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import CaseStudiesDetailsContent from '../CaseStudiesDetailsTwo/CaseStudiesDetailsContent'
import MoreProjects from '../CaseStudiesDetailsOne/MoreProjects'
import Footer from '../Layout/Footer'

export default {
    name: 'CaseStudiesDetailsPageTwo',
    components: {
        Navbar,
        PageTitle,
        CaseStudiesDetailsContent,
        MoreProjects,
        Footer,
    }
}
</script>
