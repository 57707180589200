<template>
    <div class="partner-area ptb-70 bg-fafafb">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-2 col-6 col-sm-4 col-md-4">
                    <div class="single-partner-item">
                        <img src="../../assets/images/partner/partner1.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-2 col-6 col-sm-4 col-md-4">
                    <div class="single-partner-item">
                        <img src="../../assets/images/partner/partner2.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-2 col-6 col-sm-4 col-md-4">
                    <div class="single-partner-item">
                        <img src="../../assets/images/partner/partner3.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-2 col-6 col-sm-4 col-md-4">
                    <div class="single-partner-item">
                        <img src="../../assets/images/partner/partner4.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-2 col-6 col-sm-4 col-md-4">
                    <div class="single-partner-item">
                        <img src="../../assets/images/partner/partner5.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-2 col-6 col-sm-4 col-md-4">
                    <div class="single-partner-item">
                        <img src="../../assets/images/partner/partner6.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'PartnerTwo'
}
</script>