<template>
    <div class="blog-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-12">
                    <BlogSidebar class="mb-30" />
                </div>

                <div class="col-lg-8 col-md-12">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-post bg-fffbf5">
                                <div class="post-image">
                                    <router-link to="/blog-details-two">
                                        <img src="../../assets/images/blog/blog4.jpg" alt="image">
                                    </router-link>
                                </div>
    
                                <div class="post-content">
                                    <ul class="post-meta d-flex justify-content-between align-items-center">
                                        <li>
                                            <div class="post-author d-flex align-items-center">
                                                <img src="../../assets/images/user1.jpg" class="rounded-circle" alt="image">
                                                <span>Alex Morgan</span>
                                            </div>
                                        </li>
                                        <li>
                                            <i class='flaticon-calendar'></i> April 30, 2020
                                        </li>
                                    </ul>
                                    <h3><router-link to="/blog-details-two">The Data Surrounding Higher Education</router-link></h3>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-post bg-fffbf5" >
                                <div class="post-image">
                                    <router-link to="/blog-details-two">
                                        <img src="../../assets/images/blog/blog5.jpg" alt="image">
                                    </router-link>
                                </div>
    
                                <div class="post-content">
                                    <ul class="post-meta d-flex justify-content-between align-items-center">
                                        <li>
                                            <div class="post-author d-flex align-items-center">
                                                <img src="../../assets/images/user2.jpg" class="rounded-circle" alt="image">
                                                <span>Sarah Taylor</span>
                                            </div>
                                        </li>
                                        <li>
                                            <i class='flaticon-calendar'></i> April 28, 2020
                                        </li>
                                    </ul>
                                    <h3><router-link to="/blog-details-two">Conversion Rate the Sales Funnel Optimization</router-link></h3>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-post bg-fffbf5" >
                                <div class="post-image">
                                    <router-link to="/blog-details-two">
                                        <img src="../../assets/images/blog/blog6.jpg" alt="image">
                                    </router-link>
                                </div>
    
                                <div class="post-content">
                                    <ul class="post-meta d-flex justify-content-between align-items-center">
                                        <li>
                                            <div class="post-author d-flex align-items-center">
                                                <img src="../../assets/images/user3.jpg" class="rounded-circle" alt="image">
                                                <span>David Warner</span>
                                            </div>
                                        </li>
                                        <li>
                                            <i class='flaticon-calendar'></i> April 29, 2020
                                        </li>
                                    </ul>
                                    <h3><router-link to="/blog-details-two">Business Data is changing the world’s Energy</router-link></h3>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-post bg-fffbf5">
                                <div class="post-image">
                                    <router-link to="/blog-details-two">
                                        <img src="../../assets/images/blog/blog7.jpg" alt="image">
                                    </router-link>
                                </div>
    
                                <div class="post-content">
                                    <ul class="post-meta d-flex justify-content-between align-items-center">
                                        <li>
                                            <div class="post-author d-flex align-items-center">
                                                <img src="../../assets/images/user1.jpg" class="rounded-circle" alt="image">
                                                <span>Alex Morgan</span>
                                            </div>
                                        </li>
                                        <li>
                                            <i class='flaticon-calendar'></i> April 30, 2020
                                        </li>
                                    </ul>
                                    <h3><router-link to="/blog-details-two">The data-driven approach to understanding</router-link></h3>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-post bg-fffbf5" >
                                <div class="post-image">
                                    <router-link to="/blog-details-two">
                                        <img src="../../assets/images/blog/blog8.jpg" alt="image">
                                    </router-link>
                                </div>
    
                                <div class="post-content">
                                    <ul class="post-meta d-flex justify-content-between align-items-center">
                                        <li>
                                            <div class="post-author d-flex align-items-center">
                                                <img src="../../assets/images/user2.jpg" class="rounded-circle" alt="image">
                                                <span>Sarah Taylor</span>
                                            </div>
                                        </li>
                                        <li>
                                            <i class='flaticon-calendar'></i> April 28, 2020
                                        </li>
                                    </ul>
                                    <h3><router-link to="/blog-details-two">Finding the blocks of neighboring fields</router-link></h3>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-post bg-fffbf5" >
                                <div class="post-image">
                                    <router-link to="/blog-details-two">
                                        <img src="../../assets/images/blog/blog9.jpg" alt="image">
                                    </router-link>
                                </div>
    
                                <div class="post-content">
                                    <ul class="post-meta d-flex justify-content-between align-items-center">
                                        <li>
                                            <div class="post-author d-flex align-items-center">
                                                <img src="../../assets/images/user3.jpg" class="rounded-circle" alt="image">
                                                <span>David Warner</span>
                                            </div>
                                        </li>
                                        <li>
                                            <i class='flaticon-calendar'></i> April 29, 2020
                                        </li>
                                    </ul>
                                    <h3><router-link to="/blog-details-two">Data into Your Enterprise to Drive Insights</router-link></h3>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-post bg-fffbf5">
                                <div class="post-image">
                                    <router-link to="/blog-details-two">
                                        <img src="../../assets/images/blog/blog10.jpg" alt="image">
                                    </router-link>
                                </div>
    
                                <div class="post-content">
                                    <ul class="post-meta d-flex justify-content-between align-items-center">
                                        <li>
                                            <div class="post-author d-flex align-items-center">
                                                <img src="../../assets/images/user1.jpg" class="rounded-circle" alt="image">
                                                <span>Alex Morgan</span>
                                            </div>
                                        </li>
                                        <li>
                                            <i class='flaticon-calendar'></i> April 30, 2020
                                        </li>
                                    </ul>
                                    <h3><router-link to="/blog-details-two">Introduction to Recommendation Engines</router-link></h3>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-post bg-fffbf5" >
                                <div class="post-image">
                                    <router-link to="/blog-details-two">
                                        <img src="../../assets/images/blog/blog11.jpg" alt="image">
                                    </router-link>
                                </div>
    
                                <div class="post-content">
                                    <ul class="post-meta d-flex justify-content-between align-items-center">
                                        <li>
                                            <div class="post-author d-flex align-items-center">
                                                <img src="../../assets/images/user2.jpg" class="rounded-circle" alt="image">
                                                <span>Sarah Taylor</span>
                                            </div>
                                        </li>
                                        <li>
                                            <i class='flaticon-calendar'></i> April 28, 2020
                                        </li>
                                    </ul>
                                    <h3><router-link to="/blog-details-two">5 Things You Should Know About Data</router-link></h3>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-12 col-md-12">
                            <div class="pagination-area text-center">
                                <a href="#" class="prev page-numbers"><i class='bx bx-chevrons-left'></i></a>
                                <span class="page-numbers current" aria-current="page">1</span>
                                <a href="#" class="page-numbers">2</a>
                                <a href="#" class="page-numbers">3</a>
                                <a href="#" class="page-numbers">4</a>
                                <a href="#" class="next page-numbers"><i class='bx bx-chevrons-right'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>   

<script>
import BlogSidebar from '../BlogFour/BlogSidebar'

export default {
    name: 'BlogContent',
    components: { 
        BlogSidebar
    }
}
</script>