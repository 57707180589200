<template>
    <div class="history-area pt-100">
        <div class="container">
            <div class="section-title">
                <span class="sub-title"><img src="../../assets/images/star-icon.png" alt="image"> Our History</span>
                <h2>History Begins in 2013</h2>
            </div>

            <ol class="timeline history-timeline history-timeline-style-two">
                <li class="timeline-block">
                    <div class="timeline-date">
                        <span>2013</span>
                        February 20
                        <sup>th</sup>
                    </div>

                    <div class="timeline-icon">
                        <span class="dot-badge"></span>
                    </div>

                    <div class="timeline-content">
                        <div class="row align-items-center">
                            <div class="col-lg-5 col-md-12">
                                <div class="image" data-tilt>
                                    <img src="../../assets/images/history/history1.jpg" alt="image">
                                </div>
                            </div>

                            <div class="col-lg-7 col-md-12">
                                <div class="content">
                                    <h3>Founded</h3>
                                    <p>Real innovations and a positive customer experience are the heart of successful communication. Lorem ipsum dolor sit amet, sectetur adipiscing elit, tempor incididunt ut labore et dolore magna.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="timeline-block">
                    <div class="timeline-date">
                        <span>2016</span>
                        January 14
                        <sup>th</sup>
                    </div>

                    <div class="timeline-icon">
                        <span class="dot-badge"></span>
                    </div>

                    <div class="timeline-content">
                        <div class="row align-items-center">
                            <div class="col-lg-5 col-md-12">
                                <div class="image" data-tilt>
                                    <img src="../../assets/images/history/history2.jpg" alt="image">
                                </div>
                            </div>

                            <div class="col-lg-7 col-md-12">
                                <div class="content">
                                    <h3>Global Success</h3>
                                    <p>Real innovations and a positive customer experience are the heart of successful communication. Lorem ipsum dolor sit amet, sectetur adipiscing elit, tempor incididunt ut labore et dolore magna.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="timeline-block">
                    <div class="timeline-date">
                        <span>2018</span>
                        March 25
                        <sup>th</sup>
                    </div>

                    <div class="timeline-icon">
                        <span class="dot-badge"></span>
                    </div>

                    <div class="timeline-content">
                        <div class="row align-items-center">
                            <div class="col-lg-5 col-md-12">
                                <div class="image" data-tilt>
                                    <img src="../../assets/images/history/history3.jpg" alt="image">
                                </div>
                            </div>

                            <div class="col-lg-7 col-md-12">
                                <div class="content">
                                    <h3>Founded Data Center</h3>
                                    <p>Real innovations and a positive customer experience are the heart of successful communication. Lorem ipsum dolor sit amet, sectetur adipiscing elit, tempor incididunt ut labore et dolore magna.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="timeline-block">
                    <div class="timeline-date">
                        <span>2021</span>
                        December 10
                        <sup>th</sup>
                    </div>

                    <div class="timeline-icon">
                        <span class="dot-badge"></span>
                    </div>

                    <div class="timeline-content">
                        <div class="row align-items-center">
                            <div class="col-lg-5 col-md-12">
                                <div class="image" data-tilt>
                                    <img src="../../assets/images/history/history4.jpg" alt="image">
                                </div>
                            </div>

                            <div class="col-lg-7 col-md-12">
                                <div class="content">
                                    <h3>International Award</h3>
                                    <p>Real innovations and a positive customer experience are the heart of successful communication. Lorem ipsum dolor sit amet, sectetur adipiscing elit, tempor incididunt ut labore et dolore magna.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ol>
        </div>
    </div>
</template>

<script>

export default {
    name: 'HistoryBeginsIn'
}
</script>