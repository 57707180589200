<template>
    <div>
        <NavbarStyleThree />
        <MainBanner />
        <OurFeaturedServices />
        <Partner />
        <AmazingBusinessSolution />
        <FunfactsTwo />
        <WeveDoneLotsOfWork />
        <OurAwesomeTeam class="pt-0" />
        <WhatOurClientsSayingTwo />
        <OurAffordablePricingPlans />
        <WeLikeToStartThree />
        <LatestValuableInsights class="bg-white" />
        <Footer />
    </div>
</template>

<script>
import NavbarStyleThree from '../Layout/NavbarStyleThree'
import MainBanner from '../DigitalMarketingAgency/MainBanner'
import OurFeaturedServices from '../DigitalMarketingAgency/OurFeaturedServices'
import Partner from '../DigitalMarketingAgency/Partner'
import AmazingBusinessSolution from '../DigitalMarketingAgency/AmazingBusinessSolution'
import FunfactsTwo from '../Common/FunfactsTwo'
import WeveDoneLotsOfWork from '../DigitalMarketingAgency/WeveDoneLotsOfWork'
import OurAwesomeTeam from '../Common/OurAwesomeTeam'
import WhatOurClientsSayingTwo from '../Common/WhatOurClientsSayingTwo'
import OurAffordablePricingPlans from '../Common/OurAffordablePricingPlans'
import WeLikeToStartThree from '../Common/WeLikeToStartThree'
import LatestValuableInsights from '../SEOAgency/LatestValuableInsights'
import Footer from '../Layout/Footer'

export default {
    name: 'DigitalMarketingAgencyPage',
    components: {
        NavbarStyleThree,
        MainBanner,
        OurFeaturedServices,
        Partner,
        AmazingBusinessSolution,
        FunfactsTwo,
        WeveDoneLotsOfWork,
        OurAwesomeTeam,
        WhatOurClientsSayingTwo,
        OurAffordablePricingPlans,
        WeLikeToStartThree,
        LatestValuableInsights,
        Footer,
    }
}
</script>
