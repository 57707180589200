<template>
    <div class="services-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-boxes-item">
                        <div class="icon">
                            <img src="../../assets/images/icon/icon1.png" alt="image">
                        </div>
                        <h3><router-link to="/services-details-one">Marketing Analytics</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <router-link to="/services-details-one" class="default-btn"><i class="flaticon-right"></i>Read More<span></span></router-link>
                        <div class="shape1"><img src="../../assets/images/services/services-shape2.png" alt="image"></div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-boxes-item">
                        <div class="icon">
                            <img src="../../assets/images/icon/icon2.png" alt="image">
                        </div>
                        <h3><router-link to="/services-details-one">Consumer Analytics</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <router-link to="/services-details-one" class="default-btn"><i class="flaticon-right"></i>Read More<span></span></router-link>
                        <div class="shape1"><img src="../../assets/images/services/services-shape2.png" alt="image"></div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-boxes-item">
                        <div class="icon">
                            <img src="../../assets/images/icon/icon3.png" alt="image">
                        </div>
                        <h3><router-link to="/services-details-one">Sales Analytics</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <router-link to="/services-details-one" class="default-btn"><i class="flaticon-right"></i>Read More<span></span></router-link>
                        <div class="shape1"><img src="../../assets/images/services/services-shape2.png" alt="image"></div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-boxes-item">
                        <div class="icon">
                            <img src="../../assets/images/services/services-icon1.png" alt="image">
                        </div>
                        <h3><router-link to="/services-details-one">Customer Analytics</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <router-link to="/services-details-one" class="default-btn"><i class="flaticon-right"></i>Read More<span></span></router-link>
                        <div class="shape1"><img src="../../assets/images/services/services-shape2.png" alt="image"></div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-boxes-item">
                        <div class="icon">
                            <img src="../../assets/images/services/services-icon5.png" alt="image">
                        </div>
                        <h3><router-link to="/services-details-one">Risk Analytics</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <router-link to="/services-details-one" class="default-btn"><i class="flaticon-right"></i>Read More<span></span></router-link>
                        <div class="shape1"><img src="../../assets/images/services/services-shape2.png" alt="image"></div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-boxes-item">
                        <div class="icon">
                            <img src="../../assets/images/services/services-icon4.png" alt="image">
                        </div>
                        <h3><router-link to="/services-details-one">Social Media Analytics</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <router-link to="/services-details-one" class="default-btn"><i class="flaticon-right"></i>Read More<span></span></router-link>
                        <div class="shape1"><img src="../../assets/images/services/services-shape2.png" alt="image"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ServicesContent'
}
</script>