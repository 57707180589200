<template>
    <div>
        <div class="explore-learning-area ptb-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="explore-learning-content">
                            <h2>Build Your Data Science Analytics Skills Online</h2>
                            <p>Want to learn and earn PDUs or CEUs on your schedule — anytime, anywhere? Or, pick up a new skill quickly like, project team leadership or agile? Browse our most popular online courses.</p>
                            <router-link to="/courses-grid" class="explore-learing-btn">Explore Learing</router-link>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="explore-learning-video" data-tilt>
                            <img src="../../assets/images/explore-learning.jpg" alt="image">
                            <div 
                                class="video-btn"
                                v-on:click="isPopupMethod(isPopup)"
                                style="cursor: pointer"
                            >
                                <i class="flaticon-google-play"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div 
            class="popup-video show" 
            v-if="isPopup"
        >
            <div class="d-table">
                <div class="d-table-cell">
                    <div 
                        class="popup-overlay-close"
                        v-on:click="isPopupMethod(isPopup)"
                    >
                        <div class="popup-overlay-close-line"></div>
                        <div class="popup-overlay-close-line"></div>
                    </div>
                    <div class="play-video">
                        <iframe src="https://www.youtube.com/embed/bk7McNUjWgw" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BuildYourDataScience',
    data (){
        return {
            isPopup: false
        }
    },
    methods: {
        isPopupMethod(isPopup){
            return this.isPopup = !isPopup
        },
    },
}
</script>