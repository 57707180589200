<template>
    <div class="project-start-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="project-start-image">
                        <img src="../../assets/images/project-start1.png" data-speed="0.06" data-revert="true" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="project-start-content">
                        <h2>We Like to Start Your Project With Us</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                        <router-link to="/contact" class="default-btn"><i class="flaticon-web"></i>Get Started<span></span></router-link>
                    </div>
                </div>
            </div>
        </div>

        <div class="circle-shape1"><img src="../../assets/images/shape/circle-shape1.png" alt="image"></div>
    </div>
</template>

<script>

export default {
    name: 'WeLikeToStart'
}
</script>