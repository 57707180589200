<template>
    <div class="text-block__wrap" v-if="globalData && pageData">
      <NavbarStyleThree v-if="globalData" :global-data="globalData" />
      <div class="text-block container">
        <h1 class="text-block__title">{{ pageData.title }}</h1>
        <div class="text-block__text">
          <StrapiBlocks :content="pageData.text" />
        </div>
      </div>
      <Footer v-if="globalData" :global-data="globalData" />
    </div>
  </template>

<script>
import axios from "axios";
import NavbarStyleThree from "../Layout/NavbarStyleThree";
import Footer from '../Layout/Footer'
import { StrapiBlocks } from 'vue-strapi-blocks-renderer';

export default {
    name: 'AboutUsPageOne',
    props: {
        globalData: Object,
    },
    data() {
        return {
          pageData: null,
        };
    },
    components: {
        NavbarStyleThree,
        Footer,
        StrapiBlocks
    },
    async mounted() {
        try {
          const response = await axios.get(
              `${process.env.VUE_APP_STRAPI_URL}/api/text-page3?populate=deep`,
              {
                headers: {
                  Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
                },
              }
          );
        
          if (response?.data?.data?.attributes) {
            this.pageData = response.data.data.attributes;
          }
        } catch (error) {
            console.error(error);
        }
    },
}
</script>

<style lang="scss" scoped>
.text-block {
  flex-grow: 1;
  padding-top: 24px;
  padding-bottom: 24px;
  
  &__wrap {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
}
</style>
