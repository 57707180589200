<template>
  <div v-if="true">
    <NavbarStyleThree v-if="globalData" :global-data="globalData" />
    <div>
      <PageTitle pageTitle="Диктант" />
      <div class="tests container position-relative" style="z-index: 1">
        <div v-if="activeQuestion">
          <div>Время осталось: {{ leftTime }}</div>
          <br />
          <div>
            <strong>
              {{ activeQuestionIndex + 1 }})
              {{ activeQuestion.Description }}
              <br v-if="activeQuestion.Image" />
              <img
                v-if="activeQuestion.Image"
                :src="`https://images.cadetdiktant.ru/${activeQuestion.Image}`"
                alt=""
                :key="activeQuestion.Image"
              />
              <br v-if="activeQuestion.Image" />
            </strong>
            <br />
            <br />
            <component
              class="tests__answer"
              v-for="(answer, index) in actualAnswers"
              :key="answer.id"
              :is="answer.Id === checkedAnswer?.AnswerId ? 'strong' : 'div'"
              @click="onCheckAnswer(answer)"
            >
              {{ getSymbol(index) }})
              {{ answer.Description }}
              <br v-if="answer.Image" />
              <img
                v-if="answer.Image"
                :src="`https://images.cadetdiktant.ru/${answer.Image}`"
                alt=""
              />
              <br v-if="answer.Image" />
              <br v-if="answer.Image" />
            </component>

            <br />
            <button
              class="default-btn"
              style="transform: none; margin-bottom: 0px"
              :disabled="!checkedAnswer"
              @click="onSendAnswer"
            >
              <i class="flaticon-tick"></i>Ответить<span></span>
            </button>
            <br />
            <br />
          </div>
        </div>
        <div v-else-if="tests.length">
          <div
            v-for="test in tests"
            :key="test.Id"
            style="margin-bottom: 20px"
            @click="startTest(test)"
          >
            {{ test.Title }}
          </div>
        </div>
      </div>
    </div>
    <Footer v-if="globalData" :global-data="globalData" />
  </div>
</template>

<script>
import { useGlobalStore } from "@/store/global.js";
import axios from "axios";
import PageTitle from "../Common/PageTitle";
import Footer from "../Layout/Footer";
import NavbarStyleThree from "../Layout/NavbarStyleThree.vue";

export default {
  name: "DictantPage",
  components: {
    NavbarStyleThree,
    PageTitle,
    Footer,
  },
  props: {
    globalData: Object,
  },
  data() {
    return {
      authToken: null,
      pageData: null,
      globalStore: useGlobalStore(),
      tests: [],
      selectedTest: null,
      selectedQuestion: null,
      questions: [],
      answers: [],
      activeQuestionIndex: null,
      testLeftSeconds: null,
      timer: null,
      checkedAnswer: null,
    };
  },
  computed: {
    leftTime() {
      const totalSeconds = Math.floor(this.testLeftSeconds / 1000);
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;

      if (hours > 0) {
        return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
          2,
          "0"
        )}:${String(seconds).padStart(2, "0")}`;
      } else {
        return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
          2,
          "0"
        )}`;
      }
    },
    activeQuestion() {
      return this.questions.find(
        (_, index) => index === this.activeQuestionIndex
      );
    },
    actualAnswers() {
      return this.answers.filter(
        (answer) => answer.PassingQuestionId === this.activeQuestion?.Id
      );
    },
  },
  methods: {
    updateTime() {
      if (this.testLeftSeconds > 0) {
        this.testLeftSeconds -= 1000;
      } else {
        clearInterval(this.timer);
      }
    },
    async onCheckAnswer(answer) {
      this.checkedAnswer = {
        Id: this.selectedTest.Id,
        QuestionId: answer.PassingQuestionId,
        AnswerId: answer.Id,
      };
    },
    async onSendAnswer() {
      try {
        await axios
          .post(
            "https://api.cadetdiktant.ru/passing/answer",
            this.checkedAnswer,
            {
              headers: {
                Authorization: `Bearer ${this.authToken}`,
              },
            }
          )
          .then((response) => {
            this.checkedAnswer = null;

            if (response.data.Result) {
              this.activeQuestionIndex += 1;
            } else {
              alert("Вы закончили тест. Поздравляем!!!");
              this.activeQuestionIndex = null;
              this.selectedTest = null;
              this.questions = [];
            }
          });
      } catch (error) {
        console.error(error);
      }
    },
    getSymbol(index) {
      if (index === 0) {
        return "A";
      }

      if (index === 1) {
        return "Б";
      }

      if (index === 2) {
        return "В";
      }

      if (index === 3) {
        return "Г";
      }
    },
    startTest(test) {
      this.selectedTest = test;
      const Id = this.selectedTest.Id;

      try {
        axios
          .post(
            "https://api.cadetdiktant.ru/passing/start",
            { Id },
            {
              headers: {
                Authorization: `Bearer ${this.authToken}`,
              },
            }
          )
          .then(async (response) => {
            if (response.data.Seconds === 0) {
              alert("Время для прохождения данного теста истекло");
              return;
            }

            this.testLeftSeconds = response.data.Seconds * 1000;
            this.timer = setInterval(this.updateTime, 1000);

            await axios
              .post(
                "https://api.cadetdiktant.ru/passing/answers",
                { Id },
                {
                  headers: {
                    Authorization: `Bearer ${this.authToken}`,
                  },
                }
              )
              .then((res) => {
                this.answers = res.data?.Data;

                if (this.activeQuestionIndex === null) {
                  this.activeQuestionIndex = 0;
                } else {
                  this.activeQuestionIndex += 1;
                }
              });

            await axios
              .post(
                "https://api.cadetdiktant.ru/passing/questions",
                { Id },
                {
                  headers: {
                    Authorization: `Bearer ${this.authToken}`,
                  },
                }
              )
              .then((res) => {
                this.questions = res.data?.Data;
              });
          });
      } catch (error) {
        console.error(error);
      }
    },
  },
  async mounted() {
    let username;
    let password;

    if (
      !sessionStorage.getItem("dictant_username") &&
      !sessionStorage.getItem("dictant_username")
    ) {
      username = prompt("Введите ваш логин:");
      password = prompt("Введите ваш пароль:");
      sessionStorage.setItem("dictant_username", username);
      sessionStorage.setItem("dictant_password", password);
    } else {
      username = sessionStorage.getItem("dictant_username");
      password = sessionStorage.getItem("dictant_password");
    }

    try {
      axios
        .post("https://api.cadetdiktant.ru/auth/login", {
          Login: username,
          Password: password,
          Role: "student",

          // Login: 'akunin94@gmail.com',
          // Password: 's/=!{N$vD{LJY2C',
          // Role: 'student',
        })
        .then((response) => {
          const token = response.data?.Token;

          if (token) {
            this.authToken = token;
          }

          axios
            .post(
              "https://api.cadetdiktant.ru/passing/list",
              {},
              {
                headers: {
                  Authorization: `Bearer ${this.authToken}`,
                },
              }
            )
            .then((res) => {
              this.tests = res.data?.Data;
            });
        });
    } catch (error) {
      console.error(error);
    }
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  watch: {
    testLeftSeconds(val) {
      if (val <= 0) {
        alert("Время закончилось!!!");
        this.checkedAnswer = null;
        this.activeQuestionIndex = null;
        this.selectedTest = null;
        this.questions = [];
      }
    },
  },
};
</script>

<style lang="scss">
.navbar-area.navbar-style-two.is-sticky + div {
  padding-top: 57px;

  @media (min-width: 1200px) {
    padding-top: 85.5px;
  }
}

body .page-title-area {
  padding: 60px 0 0;
}

.tests {
  padding-top: 24px;

  img {
    border: 1px solid grey;
    max-width: 280px;
  }

  &__answer {
    margin-bottom: 6px;
    display: block;
    cursor: pointer;

    img {
      width: 120px;
      border-width: 1px;
    }
  }

  strong.tests__answer img {
    border-width: 5px;
  }
}

.default-btn[disabled] {
  opacity: 0.3;
  pointer-events: none;
}
</style>
