<template>
    <div class="our-mission-area pb-100">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="our-mission-content">
                        <div class="content">
                            <span class="sub-title"><img src="../../assets/images/star-icon.png" alt="image"> Services</span>
                            <h2>Design & Development</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                            <ul class="our-mission-list mb-0">
                                <li>
                                    <span>
                                        <div class="icon">
                                            <i class="flaticon-tick"></i>
                                        </div>
                                        Responsive Design
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <div class="icon">
                                            <i class="flaticon-tick"></i>
                                        </div>
                                        React Development
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <div class="icon">
                                            <i class="flaticon-tick"></i>
                                        </div>
                                        Apps Development
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <div class="icon">
                                            <i class="flaticon-tick"></i>
                                        </div>
                                        Laravel Development
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <div class="icon">
                                            <i class="flaticon-tick"></i>
                                        </div>
                                        UX/UI Design
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <div class="icon">
                                            <i class="flaticon-tick"></i>
                                        </div>
                                        E-commerce Design
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <div class="icon">
                                            <i class="flaticon-tick"></i>
                                        </div>
                                        Web Design
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        <div class="icon">
                                            <i class="flaticon-tick"></i>
                                        </div>
                                        Print Ready Design
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="our-mission-image">
                        <img src="../../assets/images/our-mission/our-mission3.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'DesignDevelopment'
}
</script>