<template>
    <div>
        <NavbarStyleTwo />
        <MainBanner />
        <WeDifferentForms />
        <BusinessAnalytics />
        <DataScience />
        <Funfacts />
        <CheckFullSchedule />
        <TheDataScienceProcess />
        <CheckSomeRecentWork />
        <OurDataScientist />
        <WhatOurClientsSaying />
        <Partner />
        <LatestValuableInsightsTwo />
        <WeLikeToStart />
        <Footer />
    </div>
</template>

<script>
import NavbarStyleTwo from '../Layout/NavbarStyleTwo'
import MainBanner from '../DataAnalyticsMlConsulting/MainBanner'
import WeDifferentForms from '../Common/WeDifferentForms'
import BusinessAnalytics from '../Common/BusinessAnalytics'
import DataScience from '../Common/DataScience'
import Funfacts from '../Common/Funfacts'
import CheckFullSchedule from '../Common/CheckFullSchedule'
import TheDataScienceProcess from '../Common/TheDataScienceProcess'
import CheckSomeRecentWork from '../Common/CheckSomeRecentWork'
import OurDataScientist from '../Common/OurDataScientist'
import WhatOurClientsSaying from '../Common/WhatOurClientsSaying'
import Partner from '../Common/Partner'
import LatestValuableInsightsTwo from '../Common/LatestValuableInsightsTwo'
import WeLikeToStart from '../Common/WeLikeToStart'
import Footer from '../Layout/Footer'

export default {
    name: 'DataAnalyticsMlConsultingPage',
    components: {
        NavbarStyleTwo,
        MainBanner,
        WeDifferentForms,
        BusinessAnalytics,
        DataScience,
        Funfacts,
        CheckFullSchedule,
        TheDataScienceProcess,
        CheckSomeRecentWork,
        OurDataScientist,
        WhatOurClientsSaying,
        Partner,
        LatestValuableInsightsTwo,
        WeLikeToStart,
        Footer,
    }
}
</script>
