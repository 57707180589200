<template>
    <div>
        <Navbar />
        <MainBanner />
        <CreateAwesomeService />
        <WeAreDynamic />
        <FunfactsTwo />
        <OurServices />
        <DataSciencePharmaceutical />
        <OutstandingDigitalExperience />
        <WhatOurClientsSayingTwo />
        <OurAffordablePricingPlans />
        <OurAwesomeTeam />
        <GetFreeSEOAnalysis />
        <PartnerTwo />
        <LatestValuableInsights class="bg-white" />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import MainBanner from '../SEOAgency/MainBanner'
import CreateAwesomeService from '../SEOAgency/CreateAwesomeService'
import WeAreDynamic from '../SEOAgency/WeAreDynamic'
import FunfactsTwo from '../Common/FunfactsTwo'
import OurServices from '../SEOAgency/OurServices'
import DataSciencePharmaceutical from '../Common/DataSciencePharmaceutical'
import OutstandingDigitalExperience from '../SEOAgency/OutstandingDigitalExperience'
import WhatOurClientsSayingTwo from '../Common/WhatOurClientsSayingTwo'
import OurAffordablePricingPlans from '../Common/OurAffordablePricingPlans'
import OurAwesomeTeam from '../Common/OurAwesomeTeam'
import GetFreeSEOAnalysis from '../SEOAgency/GetFreeSEOAnalysis'
import PartnerTwo from '../Common/PartnerTwo'
import LatestValuableInsights from '../SEOAgency/LatestValuableInsights'
import Footer from '../Layout/Footer'

export default {
    name: 'SEOAgencyPage',
    components: {
        Navbar,
        MainBanner,
        CreateAwesomeService,
        WeAreDynamic,
        FunfactsTwo,
        OurServices,
        DataSciencePharmaceutical,
        OutstandingDigitalExperience,
        WhatOurClientsSayingTwo,
        OurAffordablePricingPlans,
        OurAwesomeTeam,
        GetFreeSEOAnalysis,
        PartnerTwo,
        LatestValuableInsights,
        Footer,
    }
}
</script>
