<template>
    <div>
        <Navbar />
        <MainBanner />
        <PartnerTwo />
        <FeaturedServices />
        <DataScienceTraining />
        <RealWorldData />
        <Funfacts />
        <OnlineDataScience />
        <BuildYourDataScience />
        <CheckFullSchedule />
        <WhatOurClientsSaying />
        <LatestValuableInsights />
        <WeLikeToStartTwo />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import MainBanner from '../StaticBannerImageThree/MainBanner'
import PartnerTwo from '../Common/PartnerTwo'
import FeaturedServices from '../StaticBannerImageThree/FeaturedServices'
import DataScienceTraining from '../StaticBannerImageThree/DataScienceTraining'
import RealWorldData from '../StaticBannerImageThree/RealWorldData'
import Funfacts from '../StaticBannerImageThree/Funfacts'
import OnlineDataScience from '../StaticBannerImageThree/OnlineDataScience'
import BuildYourDataScience from '../StaticBannerImageThree/BuildYourDataScience'
import CheckFullSchedule from '../Common/CheckFullSchedule'
import WhatOurClientsSaying from '../StaticBannerImageThree/WhatOurClientsSaying'
import LatestValuableInsights from '../Common/LatestValuableInsights'
import WeLikeToStartTwo from '../Common/WeLikeToStartTwo'
import Footer from '../Layout/Footer'

export default {
    name: 'StaticBannerImagePageThree',
    components: {
        Navbar,
        MainBanner,
        PartnerTwo,
        FeaturedServices,
        DataScienceTraining,
        RealWorldData,
        Funfacts,
        OnlineDataScience,
        BuildYourDataScience,
        CheckFullSchedule,
        WhatOurClientsSaying,
        LatestValuableInsights,
        WeLikeToStartTwo,
        Footer,
    }
}
</script>
