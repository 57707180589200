<template>
    <div class="services-area ptb-100">
        <div class="container">
            <div class="section-title">
                <h2>Our Services</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="single-services-box-item">
                        <div class="icon">
                            <img src="../../assets/images/services/services-icon7.png" alt="image">
                        </div>
                        <h3><router-link to="/services-details-one">Target Analysis</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ut labore et dolore magna aliqua.</p>
                        <router-link to="/services-details-one" class="learn-more-btn"><i class="flaticon-right"></i>Learn More</router-link>
                        <div class="shape"><img src="../../assets/images/services/services-shape4.png" alt="image"></div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="single-services-box-item">
                        <div class="icon">
                            <img src="../../assets/images/services/services-icon8.png" alt="image">
                        </div>
                        <h3><router-link to="/services-details-one">Keyword Research</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ut labore et dolore magna aliqua.</p>
                        <router-link to="/services-details-one" class="learn-more-btn"><i class="flaticon-right"></i>Learn More</router-link>
                        <div class="shape"><img src="../../assets/images/services/services-shape4.png" alt="image"></div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="single-services-box-item">
                        <div class="icon">
                            <img src="../../assets/images/services/services-icon9.png" alt="image">
                        </div>
                        <h3><router-link to="/services-details-one">Continuous Testing</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ut labore et dolore magna aliqua.</p>
                        <router-link to="/services-details-one" class="learn-more-btn"><i class="flaticon-right"></i>Learn More</router-link>
                        <div class="shape"><img src="../../assets/images/services/services-shape4.png" alt="image"></div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="single-services-box-item">
                        <div class="icon">
                            <img src="../../assets/images/services/services-icon10.png" alt="image">
                        </div>
                        <h3><router-link to="/services-details-one">Marketing Analysis</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ut labore et dolore magna aliqua.</p>
                        <router-link to="/services-details-one" class="learn-more-btn"><i class="flaticon-right"></i>Learn More</router-link>
                        <div class="shape"><img src="../../assets/images/services/services-shape4.png" alt="image"></div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="single-services-box-item">
                        <div class="icon">
                            <img src="../../assets/images/services/services-icon11.png" alt="image">
                        </div>
                        <h3><router-link to="/services-details-one">Content Optimization</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ut labore et dolore magna aliqua.</p>
                        <router-link to="/services-details-one" class="learn-more-btn"><i class="flaticon-right"></i>Learn More</router-link>
                        <div class="shape"><img src="../../assets/images/services/services-shape4.png" alt="image"></div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="single-services-box-item">
                        <div class="icon">
                            <img src="../../assets/images/services/services-icon12.png" alt="image">
                        </div>
                        <h3><router-link to="/services-details-one">Email Marketing</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ut labore et dolore magna aliqua.</p>
                        <router-link to="/services-details-one" class="learn-more-btn"><i class="flaticon-right"></i>Learn More</router-link>
                        <div class="shape"><img src="../../assets/images/services/services-shape4.png" alt="image"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'OurServices'
}
</script>