<template>
  <div v-if="globalData && pageData">
    <NavbarStyleThree v-if="globalData" :global-data="globalData" />
    <MainBanner v-if="pageData.MainPageBanner" :data="pageData.MainPageBanner" />
    <!-- <WeDifferentForms /> -->
    <DriveDigitalRevolution v-if="pageData.ImageTextBlock" :data="pageData.ImageTextBlock" />
    <!-- <WeOfferProfessionalSolutions /> -->
    <TheDataScienceProcess v-if="pageData.StepBlock" :data="pageData.StepBlock" />
    <!-- <CheckSomeRecentWork /> -->
    <!-- <OurDataScientist /> -->
    <WhatOurClientsSaying v-if="pageData.Reviews" :data="pageData.Reviews" class="pt-100" />
    <Partner v-if="pageData.partners && pageData.partners.data" :data="pageData.partners" />
    <!-- <LatestValuableInsightsTwo /> -->
    <WeLikeToStartTwo v-if="pageData.TextBlock" :data="pageData.TextBlock" />
    <Footer v-if="globalData" :global-data="globalData" />
  </div>
</template>

<script>
import axios from "axios";
import NavbarStyleThree from "../Layout/NavbarStyleThree";
import MainBanner from "../MachineLearningAlSolutions/MainBanner";
// import WeDifferentForms from "../Common/WeDifferentForms";
import DriveDigitalRevolution from "../MachineLearningAlSolutions/DriveDigitalRevolution";
// import WeOfferProfessionalSolutions from "../MachineLearningAlSolutions/WeOfferProfessionalSolutions";
import TheDataScienceProcess from "../Common/TheDataScienceProcess";
// import CheckSomeRecentWork from "../Common/CheckSomeRecentWork";
// import OurDataScientist from "../MachineLearningAlSolutions/OurDataScientist";
import WhatOurClientsSaying from "../Common/WhatOurClientsSaying";
import Partner from "../Common/Partner";
// import LatestValuableInsightsTwo from "../Common/LatestValuableInsightsTwo";
import WeLikeToStartTwo from "../Common/WeLikeToStartTwo";
import Footer from "../Layout/Footer";

export default {
  name: "MachineLearningAlSolutionsPage",
  props: {
    globalData: Object,
  },
  data() {
    return {
      pageData: null,
    };
  },
  components: {
    NavbarStyleThree,
    MainBanner,
    // WeDifferentForms,
    DriveDigitalRevolution,
    // WeOfferProfessionalSolutions,
    TheDataScienceProcess,
    // CheckSomeRecentWork,
    // OurDataScientist,
    WhatOurClientsSaying,
    Partner,
    // LatestValuableInsightsTwo,
    WeLikeToStartTwo,
    Footer,
  },

  async mounted() {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_STRAPI_URL}/api/main-page?populate=deep`,
        {
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
          },
        }
      );

      if (response?.data?.data?.attributes) {
        this.pageData = response.data.data.attributes;
      }
    } catch (error) {
      console.error(error);
    }
  },
};
</script>

<style lang="scss">
.navbar-area.navbar-style-two.is-sticky+div {
  padding-top: 57px;

  @media (min-width: 1200px) {
    padding-top: 85.5px;
  }
}
</style>