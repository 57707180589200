<template>
  <div class="funfacts-area pb-70">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-sm-6 col-6 col-md-3">
          <div class="single-funfacts-box">
            <div class="icon">
              <img
                src="../../assets/images/funfacts/funfacts1.png"
                alt="image"
              />
            </div>
            <h3>10 Years</h3>
            <p>On the market</p>
          </div>
        </div>

        <div class="col-lg-3 col-sm-6 col-6 col-md-3">
          <div class="single-funfacts-box">
            <div class="icon">
              <img
                src="../../assets/images/funfacts/funfacts2.png"
                alt="image"
              />
            </div>
            <h3>45+</h3>
            <p>Team members</p>
          </div>
        </div>

        <div class="col-lg-3 col-sm-6 col-6 col-md-3">
          <div class="single-funfacts-box">
            <div class="icon">
              <img
                src="../../assets/images/funfacts/funfacts3.png"
                alt="image"
              />
            </div>
            <h3>100%</h3>
            <p>Satisfaction rate</p>
          </div>
        </div>

        <div class="col-lg-3 col-sm-6 col-6 col-md-3">
          <div class="single-funfacts-box">
            <div class="icon">
              <img
                src="../../assets/images/funfacts/funfacts4.png"
                alt="image"
              />
            </div>
            <h3>80%</h3>
            <p>Senior scientist</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Funfacts",
};
</script>