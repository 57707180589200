<template>
    <div>
        <NavbarStyleThree />
        <PageTitle pageTitle="About Us" />
        <DriveDigitalRevolution />
        <FunfactsTwo />
        <TheDataScienceProcess />
        <WhatOurClientsSayingTwo />
        <OurDataScientist />
        <Partner />
        <Footer class="bg-white" />
    </div>
</template>

<script>
import NavbarStyleThree from '../Layout/NavbarStyleThree'
import PageTitle from '../Common/PageTitle'
import DriveDigitalRevolution from '../AboutUsFour/DriveDigitalRevolution'
import FunfactsTwo from '../Common/FunfactsTwo'
import TheDataScienceProcess from '../Common/TheDataScienceProcess'
import WhatOurClientsSayingTwo from '../Common/WhatOurClientsSayingTwo'
import OurDataScientist from '../AboutUsFour/OurDataScientist'
import Partner from '../AboutUsFour/Partner'
import Footer from '../Layout/Footer'

export default {
    name: 'AboutUsPageFour',
    components: {
        NavbarStyleThree,
        PageTitle,
        DriveDigitalRevolution,
        FunfactsTwo,
        TheDataScienceProcess,
        WhatOurClientsSayingTwo,
        OurDataScientist,
        Partner,
        Footer,
    }
}
</script>
