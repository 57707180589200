<template>
    <div class="blog-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <div class="single-blog-post bg-fffbf5">
                        <div class="post-image">
                            <router-link to="/blog-details-one">
                                <img src="../../assets/images/blog/blog4.jpg" alt="image">
                            </router-link>
                        </div>

                        <div class="post-content">
                            <ul class="post-meta d-flex justify-content-between align-items-center">
                                <li>
                                    <div class="post-author d-flex align-items-center">
                                        <img src="../../assets/images/user1.jpg" class="rounded-circle" alt="image">
                                        <span>Alex Morgan</span>
                                    </div>
                                </li>
                                <li>
                                    <i class='flaticon-calendar'></i> April 30, 2020
                                </li>
                            </ul>
                            <h3><router-link to="/blog-details-one">The Data Surrounding Higher Education and COVID-19</router-link></h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="single-blog-post bg-fffbf5" >
                        <div class="post-image">
                            <router-link to="/blog-details-one">
                                <img src="../../assets/images/blog/blog5.jpg" alt="image">
                            </router-link>
                        </div>

                        <div class="post-content">
                            <ul class="post-meta d-flex justify-content-between align-items-center">
                                <li>
                                    <div class="post-author d-flex align-items-center">
                                        <img src="../../assets/images/user2.jpg" class="rounded-circle" alt="image">
                                        <span>Sarah Taylor</span>
                                    </div>
                                </li>
                                <li>
                                    <i class='flaticon-calendar'></i> April 28, 2020
                                </li>
                            </ul>
                            <h3><router-link to="/blog-details-one">Conversion Rate Optimization: Understanding the Sales Funnel</router-link></h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="single-blog-post bg-fffbf5" >
                        <div class="post-image">
                            <router-link to="/blog-details-one">
                                <img src="../../assets/images/blog/blog6.jpg" alt="image">
                            </router-link>
                        </div>

                        <div class="post-content">
                            <ul class="post-meta d-flex justify-content-between align-items-center">
                                <li>
                                    <div class="post-author d-flex align-items-center">
                                        <img src="../../assets/images/user3.jpg" class="rounded-circle" alt="image">
                                        <span>David Warner</span>
                                    </div>
                                </li>
                                <li>
                                    <i class='flaticon-calendar'></i> April 29, 2020
                                </li>
                            </ul>
                            <h3><router-link to="/blog-details-one">Business Data is changing the world’s view towards Green Energy</router-link></h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="single-blog-post bg-fffbf5">
                        <div class="post-image">
                            <router-link to="/blog-details-one">
                                <img src="../../assets/images/blog/blog7.jpg" alt="image">
                            </router-link>
                        </div>

                        <div class="post-content">
                            <ul class="post-meta d-flex justify-content-between align-items-center">
                                <li>
                                    <div class="post-author d-flex align-items-center">
                                        <img src="../../assets/images/user1.jpg" class="rounded-circle" alt="image">
                                        <span>Alex Morgan</span>
                                    </div>
                                </li>
                                <li>
                                    <i class='flaticon-calendar'></i> April 30, 2020
                                </li>
                            </ul>
                            <h3><router-link to="/blog-details-one">The data-driven approach to understanding your users</router-link></h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="single-blog-post bg-fffbf5" >
                        <div class="post-image">
                            <router-link to="/blog-details-one">
                                <img src="../../assets/images/blog/blog8.jpg" alt="image">
                            </router-link>
                        </div>

                        <div class="post-content">
                            <ul class="post-meta d-flex justify-content-between align-items-center">
                                <li>
                                    <div class="post-author d-flex align-items-center">
                                        <img src="../../assets/images/user2.jpg" class="rounded-circle" alt="image">
                                        <span>Sarah Taylor</span>
                                    </div>
                                </li>
                                <li>
                                    <i class='flaticon-calendar'></i> April 28, 2020
                                </li>
                            </ul>
                            <h3><router-link to="/blog-details-one">Finding the blocks of neighboring fields in a matrix with Python</router-link></h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="single-blog-post bg-fffbf5" >
                        <div class="post-image">
                            <router-link to="/blog-details-one">
                                <img src="../../assets/images/blog/blog9.jpg" alt="image">
                            </router-link>
                        </div>

                        <div class="post-content">
                            <ul class="post-meta d-flex justify-content-between align-items-center">
                                <li>
                                    <div class="post-author d-flex align-items-center">
                                        <img src="../../assets/images/user3.jpg" class="rounded-circle" alt="image">
                                        <span>David Warner</span>
                                    </div>
                                </li>
                                <li>
                                    <i class='flaticon-calendar'></i> April 29, 2020
                                </li>
                            </ul>
                            <h3><router-link to="/blog-details-one">Data into Your Enterprise to Drive Actionable Insights</router-link></h3>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="pagination-area text-center">
                        <a href="#" class="prev page-numbers"><i class='bx bx-chevrons-left'></i></a>
                        <span class="page-numbers current" aria-current="page">1</span>
                        <a href="#" class="page-numbers">2</a>
                        <a href="#" class="page-numbers">3</a>
                        <a href="#" class="page-numbers">4</a>
                        <a href="#" class="next page-numbers"><i class='bx bx-chevrons-right'></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'BlogContent'
}
</script>