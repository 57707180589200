<template>
  <div v-if="data.main_page_banner_item.length">
    <div class="home-slides">
      <carousel
        :items-to-show="1"
        :autoplay="9000"
        :settings="settings"
        :wrapAround="true"
      >
        <slide v-for="slide in data.main_page_banner_item" :key="slide.id">
          <div
            class="main-banner-item"
            :style="{
              backgroundImage: `url('${process.VUE_APP_STRAPI_URL}${slide.background.data.attributes.url}')`,
            }"
          >
            <div class="d-table">
              <div class="d-table-cell">
                <div class="container">
                  <div class="banner-item-content">
                    <span class="sub-title">{{ slide.subTitle }}</span>
                    <h1>{{ slide.heading }}</h1>
                    <p>{{ slide.desc }}</p>
                    <div class="btn-box">
                      <a :href="slide.link" class="default-btn">
                        <i class="flaticon-right"></i>
                        {{ slide.linkText }}
                        <span></span>
                      </a>
                      <div
                        v-if="data.youtube_video"
                        class="video-btn"
                        v-on:click="isPopupMethod(isPopup)"
                        style="cursor: pointer"
                      >
                        <i class="flaticon-google-play"></i>
                        {{ data.youtube_video_btn_text }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </slide>

        <template #addons>
          <navigation>
            <template #next>
              <i class="flaticon-right-1"></i>
            </template>
            <template #prev>
              <i class="flaticon-left-1"></i>
            </template>
          </navigation>
        </template>
      </carousel>
    </div>

    <div class="popup-video show" v-if="isPopup">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="popup-overlay-close" v-on:click="isPopupMethod(isPopup)">
            <div class="popup-overlay-close-line"></div>
            <div class="popup-overlay-close-line"></div>
          </div>
          <div class="play-video">
            <iframe :src="data.youtube_video" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide, Navigation } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default {
  name: "MainBanner",
  methods: {
    isPopupMethod(isPopup) {
      return (this.isPopup = !isPopup);
    },
  },
  props: {
    data: Object,
  },
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      isPopup: false,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      process: process.env,
    };
  },
};
</script>