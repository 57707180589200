<template>
    <div>
        <NavbarStyleFour />
        <MainBanner />
        <WeDifferentForms class="pt-0" />
        <CloudHostingServices />
        <DesignDevelopment />
        <WereHereToHelp />
        <OurAwesomeTeam />
        <CheckSomeRecentWork />
        <PricingPlans />
        <WhatOurClientsSaying class="pt-100" />
        <Partner />
        <LatestValuableInsightsTwo />
        <WeLikeToStart />
        <Footer />
    </div>
</template>

<script>
import NavbarStyleFour from '../Layout/NavbarStyleFour'
import MainBanner from '../ItServices/MainBanner'
import WeDifferentForms from '../Common/WeDifferentForms'
import CloudHostingServices from '../ItServices/CloudHostingServices'
import DesignDevelopment from '../ItServices/DesignDevelopment'
import WereHereToHelp from '../ItServices/WereHereToHelp'
import OurAwesomeTeam from '../ItServices/OurAwesomeTeam'
import CheckSomeRecentWork from '../Common/CheckSomeRecentWork'
import PricingPlans from '../ItServices/PricingPlans'
import WhatOurClientsSaying from '../Common/WhatOurClientsSaying'
import Partner from '../Common/Partner'
import LatestValuableInsightsTwo from '../Common/LatestValuableInsightsTwo'
import WeLikeToStart from '../Common/WeLikeToStart'
import Footer from '../Layout/Footer'

export default {
    name: 'ItServicesPage',
    components: {
        NavbarStyleFour,
        MainBanner,
        WeDifferentForms,
        CloudHostingServices,
        DesignDevelopment,
        WereHereToHelp,
        OurAwesomeTeam,
        CheckSomeRecentWork,
        PricingPlans,
        WhatOurClientsSaying,
        Partner,
        LatestValuableInsightsTwo,
        WeLikeToStart,
        Footer,
    }
}
</script>
