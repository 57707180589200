<template>
    <div>
        <NavbarStyleThree />
        <PageTitle pageTitle="Blog Details" />
        <BlogDetailsContent />
        <Footer /> 
    </div>
</template>

<script>
import NavbarStyleThree from '../Layout/NavbarStyleThree'
import PageTitle from '../Common/PageTitle'
import BlogDetailsContent from '../BlogDetailsThree/BlogDetailsContent'
import Footer from '../Layout/Footer'

export default {
    name: 'BlogDetailsPageThree',
    components: {
        NavbarStyleThree,
        PageTitle,
        BlogDetailsContent,
        Footer,
    }
}
</script>
