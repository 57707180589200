<template>
    <div class="testimonials-area pt-100 pb-70 bg-fafafb">
        <div class="container">
            <div class="section-title">
                <span class="sub-title"><img src="../../assets/images/star-icon.png" alt="image"> Testimonials</span>
                <h2>What Our Clients are Saying?<span class="overlay"></span></h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
            </div>

            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <div class="single-testimonials-box">
                        <img src="../../assets/images/testimonials/testimonials1.jpg" class="shadow-sm" alt="image">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <div class="client-info">
                            <h3>Alex Maxwell</h3>
                            <span>CEO at EnvyTheme</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="single-testimonials-box">
                        <img src="../../assets/images/testimonials/testimonials2.jpg" class="shadow-sm" alt="image">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <div class="client-info">
                            <h3>David Warner</h3>
                            <span>CEO at Envato</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="single-testimonials-box">
                        <img src="../../assets/images/testimonials/testimonials3.jpg" class="shadow-sm" alt="image">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <div class="client-info">
                            <h3>Sarah Taylor</h3>
                            <span>CEO at ThemeForest</span>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="single-testimonials-box">
                        <img src="../../assets/images/testimonials/testimonials1.jpg" class="shadow-sm" alt="image">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <div class="client-info">
                            <h3>Alex Maxwell</h3>
                            <span>CEO at EnvyTheme</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="shape-img1"><img src="../../assets/images/shape/shape1.svg" alt="image"></div>
    </div>
</template>

<script>

export default {
    name: 'WhatOurClientsSayingTwo'
}
</script>