<template>
    <div class="services-area ptb-100 bg-f1f8fb">
        <div class="container">
            <div class="section-title">
                <span class="sub-title"><img src="../../assets/images/star-icon.png" alt="image"> Our Services</span>
                <h2>We Offer Professional Solutions For Business</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-services-box">
                        <div class="icon">
                            <img src="../../assets/images/services/services-icon1.png" alt="image">
                        </div>
                        <h3><router-link to="/services-details-one">Data Analytics</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                        <router-link to="/services-details-one" class="read-more-btn">Read More <i class="flaticon-right"></i></router-link>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-services-box">
                        <div class="icon">
                            <img src="../../assets/images/services/services-icon2.png" alt="image">
                        </div>
                        <h3><router-link to="/services-details-one">AI & ML Development</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                        <router-link to="/services-details-one" class="read-more-btn">Read More <i class="flaticon-right"></i></router-link>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-services-box">
                        <div class="icon">
                            <img src="../../assets/images/services/services-icon3.png" alt="image">
                        </div>
                        <h3><router-link to="/services-details-one">Data Science</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                        <router-link to="/services-details-one" class="read-more-btn">Read More <i class="flaticon-right"></i></router-link>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-services-box">
                        <div class="icon">
                            <img src="../../assets/images/services/services-icon4.png" alt="image">
                        </div>
                        <h3><router-link to="/services-details-one">Predictive Analytics</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                        <router-link to="/services-details-one" class="read-more-btn">Read More <i class="flaticon-right"></i></router-link>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-services-box">
                        <div class="icon">
                            <img src="../../assets/images/services/services-icon5.png" alt="image">
                        </div>
                        <h3><router-link to="/services-details-one">Software Development</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                        <router-link to="/services-details-one" class="read-more-btn">Read More <i class="flaticon-right"></i></router-link>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-services-box">
                        <div class="icon">
                            <img src="../../assets/images/services/services-icon6.png" alt="image">
                        </div>
                        <h3><router-link to="/services-details-one">Elastic Solutions</router-link></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                        <router-link to="/services-details-one" class="read-more-btn">Read More <i class="flaticon-right"></i></router-link>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="load-more-btn text-center">
                        <router-link to="services-two" class="default-btn"><i class="flaticon-refresh"></i>Load More<span></span></router-link>
                    </div>
                </div>
            </div>
        </div>

        <div class="circle-shape2"><img src="../../assets/images/shape/circle-shape2.png" alt="image"></div>
        <div class="lines">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'WeOfferProfessional'
}
</script>