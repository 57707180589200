<template>
  <footer class="footer-area bg-color" v-if="globalData">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="single-footer-widget">
            <router-link v-if="globalData.footer_logo" to="/" class="logo">
              <img
                :src="`${process.VUE_APP_STRAPI_URL}${globalData.footer_logo.data.attributes.url}`"
                alt="logo"
              />
            </router-link>
            <p>
              {{ globalData.shortDesc }}
            </p>
            <ul class="social-link" v-if="globalData.socials">
              <li v-for="item in globalData.socials" :key="item.id">
                <a :href="item.link" class="d-block" target="_blank"
                  ><i :class="item.icon"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-6" v-if="globalData.linksList">
          <div class="single-footer-widget pl-5">
            <h3 v-if="globalData.footer_title">
              {{ globalData.footer_title }}
            </h3>
            <ul class="footer-links-list">
              <li v-for="item in globalData.linksList" :key="item.id">
                <a :href="item.link">{{ item.title }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-6" v-if="globalData.linksList_two">
          <div class="single-footer-widget">
            <h3 v-if="globalData.footer_title_two">
              {{ globalData.footer_title_two }}
            </h3>
            <ul class="footer-links-list">
              <li v-for="item in globalData.linksList_two" :key="item.id">
                <a :href="item.link">{{ item.title }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="single-footer-widget">
            <h3 v-if="globalData.footer_title_three">
              {{ globalData.footer_title_three }}
            </h3>
            <ul class="footer-contact-info">
              <li v-if="globalData.address">
                <i class="bx bx-map"></i>{{ globalData.address }}
              </li>
              <li v-if="globalData.phone">
                <i class="bx bx-phone-call"></i
                ><a :href="`tel:${globalData.phone}`">{{ globalData.phone }}</a>
              </li>
              <li v-if="globalData.email">
                <i class="bx bx-envelope"></i
                ><a :href="`mailto:${globalData.email}`">{{
                  globalData.email
                }}</a>
              </li>
              <li v-if="globalData.fax">
                <i class="bx bxs-inbox"></i
                ><a :href="`tel:${globalData.fax}`">{{ globalData.fax }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="footer-bottom-area" v-if="false">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-6">
            <p>
              <i class="bx bx-copyright"></i>{{ currentYear }}
              <strong>Parix</strong> is Proudly Powered by
              <a target="_blank" href="https://envytheme.com/">EnvyTheme</a>
            </p>
          </div>

          <div class="col-lg-6 col-md-6">
            <ul>
              <li>
                <router-link to="/privacy-policy">Privacy Policy</router-link>
              </li>
              <li>
                <router-link to="/terms-of-service"
                  >Terms & Conditions</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-map">
      <img
        v-if="globalData.footer_pic.data"
        :src="`${process.VUE_APP_STRAPI_URL}${globalData.footer_pic?.data?.attributes.url}`"
        alt="image"
      />
    </div>
  </footer>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Footer",
  props: {
    globalData: Object,
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      process: process.env,
    };
  },
};
</script>