<template>
    <div class="case-study-area bg-fffbf5">
        <div class="case-study-slides">
             <carousel
                :items-to-show="1"
                :autoplay="5000"
                :settings='settings'
                :wrapAround="true"
            >
                <slide 
                    v-for="slide in carouselItems" 
                    :key="slide.id"
                >
                    <div class="single-case-study-item ptb-100">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-lg-6 col-md-12">
                                    <div class="case-study-content">
                                        <span class="sub-title">
                                            <img src="../../assets/images/star-icon.png" alt="image"> 
                                            {{slide.subTitle}}
                                        </span>
                                        <h2>{{slide.title}}</h2>
                                        <p>{{slide.descOne}}</p>
                                        <p>{{slide.descTwo}}</p>
                                        <router-link to="/case-studies-details-one" class="default-btn">
                                            <i class="flaticon-view"></i>Details More<span></span>
                                        </router-link>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <div class="case-study-image" data-tilt>
                                        <router-link to="/case-studies-details-one" class="d-block">
                                            <img :src="slide.image" alt="image">
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </slide>

                <template #addons>
                    <navigation>
                        <template #next>
                            <i class='flaticon-right-1'></i>
                        </template>
                        <template #prev>
                            <i class='flaticon-left-1'></i>
                        </template>
                    </navigation>
                </template>
            </carousel>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'DataSciencePharmaceutical',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                subTitle: 'Case study #1',
                title: 'Data Science in Pharmaceutical Industries',
                descOne: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.',
                descTwo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.',
                image: require('../../assets/images/case-study1.jpg'),
            },
            {
                id: 2,
                subTitle: 'Case study #2',
                title: 'Mathematics, Advanced Statistics in Python',
                descOne: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.',
                descTwo: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.',
                image: require('../../assets/images/case-study2.jpg'),
            },
        ],
    }),
})
</script>