import { createWebHistory, createRouter } from 'vue-router';

// All Pages
import DataScienceMlCompanyPage from '../components/Pages/DataScienceMlCompanyPage';
import DataAnalyticsAlStartupPage from '../components/Pages/DataAnalyticsAlStartupPage';
import DataScienceOnlineCoursesPage from '../components/Pages/DataScienceOnlineCoursesPage';
import BigDataAnalysisStartupPage from '../components/Pages/BigDataAnalysisStartupPage';
import DataAnalyticsMlConsultingPage from '../components/Pages/DataAnalyticsMlConsultingPage';
import MachineLearningAlSolutionsPage from '../components/Pages/MachineLearningAlSolutionsPage';
import ItServicesPage from '../components/Pages/ItServicesPage';
import SEOAgencyPage from '../components/Pages/SEOAgencyPage';
import DigitalMarketingAgencyPage from '../components/Pages/DigitalMarketingAgencyPage';
import StaticBannerImagePageOne from '../components/Pages/StaticBannerImagePageOne';
import StaticBannerImagePageTwo from '../components/Pages/StaticBannerImagePageTwo';
import StaticBannerImagePageThree from '../components/Pages/StaticBannerImagePageThree';
import StaticBannerImagePageFour from '../components/Pages/StaticBannerImagePageFour';
import TextPage1 from '../components/Pages/TextPage1';
import TextPage2 from '../components/Pages/TextPage2';
import TextPage3 from '../components/Pages/TextPage3';
import TextPage4 from '../components/Pages/TextPage4';
import TextPage5 from '../components/Pages/TextPage5';
// import AboutUsPageOne from '../components/Pages/AboutUsPageOne';
import AboutUsPageTwo from '../components/Pages/AboutUsPageTwo';
import AboutUsPageThree from '../components/Pages/AboutUsPageThree';
import AboutUsPageFour from '../components/Pages/AboutUsPageFour';
import HistoryPage from '../components/Pages/HistoryPage';
import TestimonialsPage from '../components/Pages/TestimonialsPage';
import TeamPageOne from '../components/Pages/TeamPageOne';
import TeamPageTwo from '../components/Pages/TeamPageTwo';
import ServicesPageOne from '../components/Pages/ServicesPageOne';
import ServicesPageTwo from '../components/Pages/ServicesPageTwo';
import ServicesPageThree from '../components/Pages/ServicesPageThree';
import ServicesPageFour from '../components/Pages/ServicesPageFour';
import ServicesPageFive from '../components/Pages/ServicesPageFive';
import ServicesPageSix from '../components/Pages/ServicesPageSix';
import ServicesPageSeven from '../components/Pages/ServicesPageSeven';
import ServicesPageEight from '../components/Pages/ServicesPageEight';
import ServiceDetailsPageOne from '../components/Pages/ServiceDetailsPageOne';
import ServiceDetailsPageTwo from '../components/Pages/ServiceDetailsPageTwo';
import CaseStudiesPageOne from '../components/Pages/CaseStudiesPageOne';
import CaseStudiesPageTwo from '../components/Pages/CaseStudiesPageTwo';
import CaseStudiesPageThree from '../components/Pages/CaseStudiesPageThree';
import CaseStudiesPageFour from '../components/Pages/CaseStudiesPageFour';
import CaseStudiesDetailsPageOne from '../components/Pages/CaseStudiesDetailsPageOne';
import CaseStudiesDetailsPageTwo from '../components/Pages/CaseStudiesDetailsPageTwo';
import CaseStudiesDetailsPageThree from '../components/Pages/CaseStudiesDetailsPageThree';
import BlogPageOne from '../components/Pages/BlogPageOne';
import BlogPageTwo from '../components/Pages/BlogPageTwo';
import BlogPageThree from '../components/Pages/BlogPageThree';
import BlogPageFour from '../components/Pages/BlogPageFour';
import BlogPageFive from '../components/Pages/BlogPageFive';
import BlogDetailsPageOne from '../components/Pages/BlogDetailsPageOne';
import BlogDetailsPageTwo from '../components/Pages/BlogDetailsPageTwo';
import BlogDetailsPageThree from '../components/Pages/BlogDetailsPageThree';
import ContactPage from '../components/Pages/ContactPage';
import CoursesGridPage from '../components/Pages/CoursesGridPage';
import PartnerPage from '../components/Pages/PartnerPage';
import ErrorPage from '../components/Pages/ErrorPage';
import MembershipLevelsPage from '../components/Pages/MembershipLevelsPage';
import LoginRegisterPage from '../components/Pages/LoginRegisterPage';
import LoginRegisterStudentPage from '../components/Pages/LoginRegisterStudentPage';
import DictantPage from '../components/Pages/DictantPage';
import ForgetPassword from '../components/Pages/ForgetPassword';
import PurchaseGuidePage from '../components/Pages/PurchaseGuidePage';
import PrivacyPolicyPage from '../components/Pages/PrivacyPolicyPage';
import TermsOfServicePage from '../components/Pages/TermsOfServicePage';
import ProductsPageOne from '../components/Pages/ProductsPageOne';
import ProductsPageTwo from '../components/Pages/ProductsPageTwo';
import CartPage from '../components/Pages/CartPage';
import CheckoutPage from '../components/Pages/CheckoutPage';
import ProductsDetailsPage from '../components/Pages/ProductsDetailsPage';
import EventsPage from '../components/Pages/EventsPage';
import CoursesDetailsPage from '../components/Pages/CoursesDetailsPage';
import FaqPage from '../components/Pages/FaqPage';
import EventsDetailsPage from '../components/Pages/EventsDetailsPage';
import CoursesRightSidebarPage from '../components/Pages/CoursesRightSidebarPage';
import ComingSoonPage from '../components/Pages/ComingSoonPage';

const routes = [
	{
		path: '/',
		component: MachineLearningAlSolutionsPage,
		// component: DataScienceMlCompanyPage
	},
	{
		path: '/data-analytics-ai-startup',
		component: DataAnalyticsAlStartupPage,
	},
	{
		path: '/data-science-online-courses',
		component: DataScienceOnlineCoursesPage,
	},
	{
		path: '/big-data-analysis-startup',
		component: BigDataAnalysisStartupPage,
	},
	{
		path: '/data-analytics-ml-consulting',
		component: DataAnalyticsMlConsultingPage,
	},
	{
		path: '/machine-learning-ai-solutions',
		component: DataScienceMlCompanyPage,
		// component: MachineLearningAlSolutionsPage,
	},
	{ path: '/it-services', component: ItServicesPage },
	{ path: '/seo-agency', component: SEOAgencyPage },
	{
		path: '/digital-marketing-agency',
		component: DigitalMarketingAgencyPage,
	},
	{ path: '/static-banner-image-one', component: StaticBannerImagePageOne },
	{ path: '/static-banner-image-two', component: StaticBannerImagePageTwo },
	{
		path: '/static-banner-image-three',
		component: StaticBannerImagePageThree,
	},
	{ path: '/static-banner-image-four', component: StaticBannerImagePageFour },
	{ path: '/polozhenie', component: TextPage1 },
	{ path: '/aboutdictant', component: TextPage2 },
	{ path: '/about', component: TextPage3 },
	{ path: '/result', component: TextPage4 },
	{ path: '/soglasiye', component: TextPage5 },
	{ path: '/about-us-two', component: AboutUsPageTwo },
	{ path: '/about-us-three', component: AboutUsPageThree },
	{ path: '/about-us-four', component: AboutUsPageFour },
	{ path: '/history', component: HistoryPage },
	{ path: '/testimonials', component: TestimonialsPage },
	{ path: '/team-one', component: TeamPageOne },
	{ path: '/team-two', component: TeamPageTwo },
	{ path: '/services-one', component: ServicesPageOne },
	{ path: '/services-two', component: ServicesPageTwo },
	{ path: '/services-three', component: ServicesPageThree },
	{ path: '/services-four', component: ServicesPageFour },
	{ path: '/services-five', component: ServicesPageFive },
	{ path: '/services-six', component: ServicesPageSix },
	{ path: '/services-seven', component: ServicesPageSeven },
	{ path: '/services-eight', component: ServicesPageEight },
	{ path: '/services-details-one', component: ServiceDetailsPageOne },
	{ path: '/services-details-two', component: ServiceDetailsPageTwo },
	{ path: '/case-studies-one', component: CaseStudiesPageOne },
	{ path: '/case-studies-two', component: CaseStudiesPageTwo },
	{ path: '/case-studies-three', component: CaseStudiesPageThree },
	{ path: '/case-studies-four', component: CaseStudiesPageFour },
	{ path: '/case-studies-details-one', component: CaseStudiesDetailsPageOne },
	{ path: '/case-studies-details-two', component: CaseStudiesDetailsPageTwo },
	{
		path: '/case-studies-details-three',
		component: CaseStudiesDetailsPageThree,
	},
	{ path: '/blog-one', component: BlogPageOne },
	{ path: '/blog-two', component: BlogPageTwo },
	{ path: '/blog-three', component: BlogPageThree },
	{ path: '/blog-four', component: BlogPageFour },
	{ path: '/blog-five', component: BlogPageFive },
	{ path: '/blog-details-one', component: BlogDetailsPageOne },
	{ path: '/blog-details-two', component: BlogDetailsPageTwo },
	{ path: '/blog-details-three', component: BlogDetailsPageThree },
	{ path: '/contact', component: ContactPage },
	{ path: '/courses-grid', component: CoursesGridPage },
	{ path: '/partner', component: PartnerPage },
	{ path: '/:pathMatch(.*)*', component: ErrorPage },
	{ path: '/coming-soon', component: ComingSoonPage },
	{ path: '/membership-levels', component: MembershipLevelsPage },
	{ path: '/register-teacher', component: LoginRegisterPage },
	{ path: '/register', component: LoginRegisterStudentPage },
	{ path: '/dictant', component: DictantPage },
	{ path: '/lost-your-password', component: ForgetPassword },
	{ path: '/purchase-guide', component: PurchaseGuidePage },
	{ path: '/privacy-policy', component: PrivacyPolicyPage },
	{ path: '/terms-of-service', component: TermsOfServicePage },
	{ path: '/products-one', component: ProductsPageOne },
	{ path: '/products-two', component: ProductsPageTwo },
	{ path: '/cart', component: CartPage },
	{ path: '/checkout', component: CheckoutPage },
	{ path: '/products-details', component: ProductsDetailsPage },
	{ path: '/events', component: EventsPage },
	{ path: '/courses-details', component: CoursesDetailsPage },
	{ path: '/faq', component: FaqPage },
	{ path: '/events-details', component: EventsDetailsPage },
	{ path: '/courses-right-sidebar', component: CoursesRightSidebarPage },
];

const router = createRouter({
	history: createWebHistory(),
	linkExactActiveClass: 'active',
	routes,
	scrollBehavior() {
		return { top: 0 };
	},
});

export default router;
