<template>
  <div :class="['navbar-area navbar-style-two', { 'is-sticky': isSticky }]">
    <div class="parix-nav">
      <div class="container">
        <nav class="navbar navbar-expand-md navbar-light">
          <router-link v-if="globalData.image" class="navbar-brand" to="/">
            <img :src="`${process.VUE_APP_STRAPI_URL}${globalData.image.data.attributes.url}`" alt="logo" />
          </router-link>

          <div class="navbar-toggler" @click="active = !active" :aria-pressed="active ? 'true' : 'false'"
            v-bind:class="{ active: button_active_state }" v-on:click="button_active_state = !button_active_state">
            <span class="icon-bar top-bar"></span>
            <span class="icon-bar middle-bar"></span>
            <span class="icon-bar bottom-bar"></span>
          </div>

          <div class="collapse navbar-collapse" :class="{ show: active }">
            <ul class="navbar-nav">
              <li class="nav-item" v-for="item in globalData.HeaderMenu" :key="item.title">
                <AppLink :link="item.level2 && item.level2.length ? '#' : item.link" class="nav-link">{{ item.title }}
                  <i v-if="item.level2.length" class="bx bx-chevron-down"></i>
                </AppLink>
                <ul v-if="item.level2.length" class="dropdown-menu">
                  <li class="nav-item" v-for="level2 in item.level2" :key="level2.title">
                    <AppLink :link="level2.level3 && level2.level3.length
                      ? '#'
                      : level2.link
                      " class="nav-link">{{ level2.title
                      }}<i v-if="level2.level3 && level2.level3.length" class="bx bx-chevron-right"></i></AppLink>
                    <ul v-if="level2.level3 && level2.level3.length" class="dropdown-menu">
                      <li class="nav-item" v-for="level3 in level2.level3" :key="level3.title">
                        <AppLink :link="level3.link" class="nav-link">{{
                          level3.title
                        }}</AppLink>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <div v-if="globalData.HeaderButtonLink && globalData.HeaderButtonText"
            class="others-option d-flex align-items-center">
            <div class="option-item dropdown register-type-dropdown">
              <button disabled style="transform: none; margin-bottom: 0;" class="default-btn" data-bs-toggle="dropdown"
                aria-expanded="false">
                <i class="flaticon-right"></i>
                {{ globalData.HeaderButtonText }}<span></span>
              </button>
              <ul class="dropdown-menu" style="left: auto; right: 0;">
                <li><router-link to="/register" class="dropdown-item" href="#">Ученик</router-link></li>
                <li><router-link to="/register-teacher" class="dropdown-item" href="#">Преподователь</router-link></li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import AppLink from "@/components/Link.vue";

export default {
  name: "NavbarStyleThree",
  components: {
    AppLink,
  },
  props: {
    globalData: Object,
  },
  data() {
    return {
      isSticky: false,
      active: false,
      button_active_state: false,
      process: process.env,
    };
  },

  mounted() {
    const that = this;
    window.addEventListener("scroll", () => {
      let scrollPos = window.scrollY;
      if (scrollPos >= 100) {
        that.isSticky = true;
      } else {
        that.isSticky = false;
      }
    });
  },
};
</script>