<template>
    <div>
        <NavbarStyleThree />
        <PageTitle pageTitle="Case Studies Details" />
        <CaseStudiesDetailsContent />
        <MoreProjects />
        <Footer /> 
    </div>
</template>

<script>
import NavbarStyleThree from '../Layout/NavbarStyleThree'
import PageTitle from '../Common/PageTitle'
import CaseStudiesDetailsContent from '../CaseStudiesDetailsThree/CaseStudiesDetailsContent'
import MoreProjects from '../CaseStudiesDetailsOne/MoreProjects'
import Footer from '../Layout/Footer'

export default {
    name: 'CaseStudiesDetailsPageThree',
    components: {
        NavbarStyleThree,
        PageTitle,
        CaseStudiesDetailsContent,
        MoreProjects,
        Footer,
    }
}
</script>
