<template>
    <div class="seo-analysis-section pb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="analysis-img">
                        <img src="../../assets/images/seo-analysis.png" alt="Image">
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="analysis-form">
                        <span class="sub-title">Analysis</span>
                        <h2>Get Free SEO Analysis</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <form>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" id="your_name" placeholder="Name">
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input type="email" class="form-control" id="your_email" placeholder="Email">
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" id="your_name" placeholder="Phone Number">
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" id="website" placeholder="Website">
                                    </div>
                                </div>
                            </div>
                            
                            <button type="submit" class="default-btn"><i class="flaticon-tick"></i>Check Now <span></span></button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'GetFreeSEOAnalysis'
}
</script>