import { defineStore } from 'pinia'

export const useGlobalStore = defineStore('global', {
  state: () => ({
    jwt: 0,
    userData: 0
  }),
  actions: {
    setJwt(value) {
      this.jwt = value
    },
    setUserData(value) {
      this.userData = value
    }
  }
})