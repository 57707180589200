<template>
  <div v-if="data">
    <div class="about-area pt-70 pb-100">
      <div class="container">
        <div class="row align-items-center">
          <div v-if="data.image.data" class="col-lg-6 col-md-12">
            <div class="about-image-box">
              <img
                :src="`${process.VUE_APP_STRAPI_URL}${data.image.data.attributes.url}`"
                alt="image"
              />

              <div class="box" v-if="data.VideoText && data.VideoLink">
                <div
                  class="video-btn"
                  v-on:click="isPopupMethod(isPopup)"
                  style="cursor: pointer"
                >
                  <i class="flaticon-google-play"></i>
                  {{ data.VideoText }}
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-md-12"
            :class="{
              'col-lg-6': data.image.data,
              'col-lg-12': !data.image.data,
            }"
          >
            <div class="about-content">
              <div
                class="content"
                :style="{ maxWidth: data.image.data ? '' : 'none' }"
              >
                <span v-if="data.subtitle" class="sub-title"
                  ><img src="../../assets/images/star-icon.png" alt="image" />
                  {{ data.subtitle }}</span
                >
                <h2 v-if="data.title">{{ data.title }}</h2>
                <p v-if="data.description" v-html="data.description.replace('\n', '<br /><br />')" />
                <ul
                  v-if="
                    data.block1_title ||
                    data.block1_text ||
                    data.block1_pic ||
                    data.block2_title ||
                    data.block2_text ||
                    data.block2_pic
                  "
                  class="features-list"
                >
                  <li
                    v-if="
                      data.block1_title || data.block1_text || data.block1_pic
                    "
                  >
                    <span>
                      <div class="icon">
                        <img
                          :src="`${process.VUE_APP_STRAPI_URL}${data.block1_pic.data.attributes.url}`"
                          alt="image"
                        />
                      </div>
                      <h3>{{ data.block1_title }}</h3>
                      <p v-html="data.block1_text.replace('\n', '<br /><br />')" />
                    </span>
                  </li>

                  <li
                    v-if="
                      data.block2_title || data.block2_text || data.block2_pic
                    "
                  >
                    <span>
                      <div class="icon">
                        <img
                          :src="`${process.VUE_APP_STRAPI_URL}${data.block2_pic.data.attributes.url}`"
                          alt="image"
                        />
                      </div>
                      <h3>{{ data.block2_title }}</h3>
                      <p>{{ data.block2_text }}</p>
                    </span>
                  </li>
                </ul>
                <p>
                  {{ data.text }}
                </p>
                <AppLink
                  v-if="data.ButtonLink && data.ButtonText"
                  :link="data.ButtonLink"
                  class="default-btn"
                  ><i class="flaticon-right"></i>{{ data.ButtonText
                  }}<span></span
                ></AppLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="circle-shape1">
        <img
          v-if="data.bg?.data"
          :src="`${process.VUE_APP_STRAPI_URL}${data.bg?.data?.attributes.url}`"
          alt="image"
        />
      </div>
    </div>

    <div class="popup-video show" v-if="isPopup">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="popup-overlay-close" v-on:click="isPopupMethod(isPopup)">
            <div class="popup-overlay-close-line"></div>
            <div class="popup-overlay-close-line"></div>
          </div>
          <div class="play-video">
            <iframe
              src="https://www.youtube.com/embed/bk7McNUjWgw"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppLink from "@/components/Link.vue";

export default {
  name: "DriveDigitalRevolution",
  props: {
    data: Object,
  },
  components: {
    AppLink,
  },
  data() {
    return {
      isPopup: false,
      process: process.env,
    };
  },
  methods: {
    isPopupMethod(isPopup) {
      return (this.isPopup = !isPopup);
    },
  },
};
</script>