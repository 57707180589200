<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Case Studies 2 Columns" />
        <CaseStudiesContent />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Common/PageTitle'
import CaseStudiesContent from '../CaseStudiesOne/CaseStudiesContent'
import Footer from '../Layout/Footer'

export default {
    name: 'CaseStudiesPageOne',
    components: {
        Navbar,
        PageTitle,
        CaseStudiesContent,
        Footer,
    }
}
</script>
