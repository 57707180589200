<template>
  <router-link v-if="isRouterLink" :to="link" class="routerlink">
    <slot />
  </router-link>
  <a v-else :href="link" class="aaa">
    <slot />
  </a>
</template>

<script>
export default {
  name: "AppLink",

  props: {
    link: String,
  },

  computed: {
    isRouterLink() {
      return this.link && this.link.startsWith("/");
    },
  },
};
</script>