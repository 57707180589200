import { createApp } from "vue";
import { createPinia } from 'pinia'
import App from "./App.vue";
import router from "./router";
import BootstrapVue3 from "bootstrap-vue-3";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import "./assets/custom.scss";
import "./assets/css/boxicons.min.css";

const app = createApp(App).use(router);
app.use(BootstrapVue3);
const pinia = createPinia()
app.use(pinia)
app.mount("#app");
