<template>
    <div class="container">
        <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Our History</h3>
                        <p>Real innovations and a positive customer experience are the heart of successful communication.</p>
                        
                        <ul class="features-list">
                            <li><i class="flaticon-tick"></i> Activate Listening</li>
                            <li><i class="flaticon-tick"></i> Brilliant minds</li>
                            <li><i class="flaticon-tick"></i> Better. Best. Wow!</li>
                            <li><i class="flaticon-tick"></i> Branding it better!</li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Our Mission</h3>
                        <p>Real innovations and a positive customer experience are the heart of successful communication.</p>
                        
                        <ul class="features-list">
                            <li><i class="flaticon-tick"></i> Creating. Results.</li>
                            <li><i class="flaticon-tick"></i> Expect more</li>
                            <li><i class="flaticon-tick"></i> Good thinking</li>
                            <li><i class="flaticon-tick"></i> In real we trust</li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="about-text">
                        <h3>Who we are</h3>
                        <p>Real innovations and a positive customer experience are the heart of successful communication.</p>
                        
                        <ul class="features-list">
                            <li><i class="flaticon-tick"></i> Stay real. Always.</li>
                            <li><i class="flaticon-tick"></i> We have you covered</li>
                            <li><i class="flaticon-tick"></i> We turn heads</li>
                            <li><i class="flaticon-tick"></i> Your brand, promoted</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'AboutInnerContent'
}
</script>