<template>
    <div class="hero-banner">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-5 col-md-12">
                    <div class="hero-banner-content">
                        <h1>Learn Data Science</h1>
                        <p>Keeping your skills up to date is essential and learning Data Science including oportunites on Big Data can drive you to become extraordinary professionals.</p>
                        <div class="btn-box">
                            <router-link to="/events" class="default-btn"><i class="flaticon-right"></i>Explore Bootcamps<span></span></router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="hero-banner-animation-image">
                        <img src="../../assets/images/main-banner/banner-three/banner-three1.png" alt="image">
                        <img src="../../assets/images/main-banner/banner-three/banner-three2.png" alt="image">
                        <img src="../../assets/images/main-banner/banner-three/banner-three3.png" alt="image">
                        <img src="../../assets/images/main-banner/banner-three/banner-three4.png" alt="image">
                        <img src="../../assets/images/main-banner/banner-three/banner-three5.png" alt="image">
                        <img src="../../assets/images/main-banner/banner-three/banner-three6.png" alt="image">
                        <img src="../../assets/images/main-banner/banner-three/banner-three7.png" alt="image">
                        <img src="../../assets/images/main-banner/banner-three/banner-three8.png" alt="image">
                        <img src="../../assets/images/main-banner/banner-three/banner-three9.png" alt="image">
                        <img src="../../assets/images/main-banner/banner-three/banner-three10.png" alt="image">
                        <img src="../../assets/images/main-banner/banner-three/banner-three-main-pic.png" alt="image">
                    </div>
                </div>
            </div>
        </div>

        <div class="vector-shape7"><img src="../../assets/images/shape/vector-shape7.png" alt="image"></div>
        <div class="vector-shape8"><img src="../../assets/images/shape/vector-shape8.png" alt="image"></div>
    </div>
</template>

<script>

export default {
    name: 'MainBanner'
}
</script>